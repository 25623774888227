/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faCirclePlus,
  faClapperboard,
  faDownload,
  faPenToSquare,
  faSearch,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { DateRange } from 'react-date-range'
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  Modal,
  InputGroup,
  Container,
  Image,
} from 'react-bootstrap'
import { UploaderContext, AuthContext } from './ContextProvider'
import VideoEditorModal from './VideoEditorModal'
import ClipEndModal from './ClipEndModal'
import apiServices from '../services/apiServices'

function DeleteModal({ setting }) {
  const { show, name, handleClose } = setting

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
    >
      <Modal.Header closeButton />
      <Modal.Body className="p-4">
        <div className="d-flex">
          <FontAwesomeIcon
            className="px-0 m-auto text-chelonia text-center"
            style={{
              height: '100px',
            }}
            icon={faCircleExclamation}
          />
        </div>
        <h5 className="text-center lh-lg text-chelonia">
          <br />
          警告！刪除後無法復原！
          <br />
          仍要刪除
          <span className="text-danger">{`「${name}」素材`}</span>
          嗎？
        </h5>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto me-2"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(true)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function ProjectModal({ setting }) {
  const {
    show,
    form,
    defaultValue = {},
    setDuration,
    handleClose,
    data,
    onDataChange,
  } = setting
  const [showDate, setshowDate] = useState(false)

  const [date, setdate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
    >
      <Modal.Header closeButton>
        {defaultValue.setting ? `編輯素材` : `新建素材`}
      </Modal.Header>
      <Modal.Body className="p-4">
        {form.map((f, i) => {
          switch (f.type) {
            case 'date':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name={f.name}
                      type="text"
                      value={data[f.name] || f.placeholder}
                      placeholder={f.placeholder}
                      onFocus={() => setshowDate(!showDate)}
                      readOnly
                    />
                    <div
                      style={{
                        height: showDate ? '100%' : '0%',
                        transition: 'height .3s ease-in',
                        position: 'absolute',
                        left: '-50',
                      }}
                    >
                      {showDate && (
                        <DateRange
                          ranges={[date]}
                          editableDateInputs
                          onChange={({ selection }) => {
                            setdate(selection)
                            onDataChange({
                              target: {
                                name: 'date',
                                value: `${moment(selection.startDate).format(
                                  'yyyy-MM-DD'
                                )}-${moment(selection.endDate).format(
                                  'yyyy-MM-DD'
                                )}`,
                              },
                            })
                          }}
                          moveRangeOnFirstSelection={false}
                        />
                      )}
                    </div>
                    <Button
                      variant="chelonia2"
                      onClick={() => setshowDate(!showDate)}
                    >
                      確認
                    </Button>
                  </InputGroup>
                </React.Fragment>
              )
            case 'select':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Select
                    name={f.name}
                    type={f.type}
                    value={data[f.name] || ''}
                    onChange={onDataChange}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  >
                    <option value="">未選擇</option>
                    {f.content.map((c) => (
                      <option value={c}>{c}</option>
                    ))}
                  </Form.Select>
                </React.Fragment>
              )
            case 'file':
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Control
                    name={f.name}
                    type={f.type}
                    onChange={async (e) => {
                      // const formData = new FormData()
                      // formData.append('file', e.target.files[0])
                      onDataChange({
                        target: {
                          name: f.name,
                          value: e.target.files[0],
                        },
                      })
                      console.log('file append')
                      const temp = URL.createObjectURL(e.target.files[0])
                      const audio = document.createElement('audio')
                      audio.muted = true
                      console.log('audio created')
                      const source = document.createElement('source')
                      source.src = temp // --> blob URL
                      audio.preload = 'metadata'
                      audio.appendChild(source)
                      console.log('reading metadata')
                      audio.onloadedmetadata = () => {
                        console.log(audio.duration)
                        setDuration(audio.duration)
                      }
                      // const uploaded = await apiServices.data({
                      //   path: `material/file`,
                      //   method: 'post',
                      //   data: formData,
                      //   contentType: 'multipart/form-data',
                      // })
                      // console.log(uploaded)
                    }}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  />
                </React.Fragment>
              )
            default:
              return (
                <React.Fragment key={i}>
                  <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
                    {f.label}
                  </Form.Label>

                  <Form.Control
                    name={f.name}
                    type={f.type}
                    value={data[f.name] || ''}
                    onChange={onDataChange}
                    placeholder={f.placeholder}
                    onFocus={() => setshowDate(false)}
                  />
                </React.Fragment>
              )
          }
        })}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(true)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function MaterialList({ setting = {} }) {
  // const navigate = useNavigate()
  const {
    hasCheckBox = false,
    hasTools = true,
    selectedMaterial,
    allowTypes = [],
    handleSelect = () => {},
  } = setting

  const { auth } = useContext(AuthContext)
  const { materials, handleUpload, handleDelete, handleModify } =
    useContext(UploaderContext)

  const [subtitle, setsubtitle] = useState([])
  const getSubtitle = async (offset = 0) => {
    const res = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/subtitle/management/?limit=100&offset=${offset}`,
      method: 'get',
    })
    if (res.count && res.count >= offset + 100) {
      const res2 = await getSubtitle(offset + 100)
      return res.data.concat(res2.data)
    }
    return res.data
  }
  const refreshSubtitle = async () => {
    const res = await getSubtitle()
    if (res.length) {
      setsubtitle(res)
    }
  }
  useEffect(() => {
    refreshSubtitle()
  }, [])
  console.log(subtitle)

  const [warn, setWarn] = useState({
    show: false,
    text: '',
    handleClose: () => {},
  })

  const parseJSON = (inputString, fallback) => {
    if (inputString) {
      try {
        return JSON.parse(inputString)
      } catch (e) {
        return fallback
      }
    }
    return fallback
  }

  const [showVideoEditor, setshowVideoEditor] = useState(false)

  const form = [
    {
      name: 'file',
      label: '選擇素材',
      placeholder: '',
      type: 'file',
    },
    {
      name: 'type',
      label: '素材類型',
      placeholder: '',
      type: 'select',
      content: ['浮水印', '影片邊框', '轉場動畫', '影片'],
    },
  ]

  const [selectedId, setselectedId] = useState('')

  const [show, setshow] = useState(false)
  const [duration, setDuration] = useState(0)
  const [data, setdata] = useState({})
  const onDataChange = (e) =>
    setdata({ ...data, [e.target.name]: e.target.value })
  useEffect(() => {
    if (show) {
      setdata(
        form.reduce(
          (prev, cur) => ({
            ...prev,
            [cur.name]: '',
          }),
          {}
        )
      )
    }
  }, [show])

  const handleClose = (value) => {
    setshow(false)
    if (!value) return
    const { file, type } = data
    const types = {
      浮水印: 'wm',
      轉場動畫: 'ta',
      影片邊框: 'br',
      // 影片: 'clip',
      影片: 'video',
      // 'PSD Template': '',
    }
    handleUpload(types[type], file, (uploaded) => {
      const { object_id } = uploaded
      const video = document.createElement('video')
      console.log('video created')
      video.addEventListener('loadedmetadata', async () => {
        console.log(video.videoWidth, video.videoHeight)
        await handleModify(types[type], object_id || '', {
          description: JSON.stringify({
            type: types[type],
            size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
            dimension: {
              height: video.videoHeight,
              width: video.videoWidth,
            },
            duration,
            created_on: moment().format('yyyy-MM-DD HH:mm:ss'),
            created_by: auth.username,
          }),
        })
        video.remove()
      })
      video.src = URL.createObjectURL(file)
      console.log(video)
    })
    // console.log('adding')
    // if (selectedId) handleEdit(value)

    // handleAdd(value)
  }

  const [deleteShow, setdeleteShow] = useState(false)
  const handleDeleteClose = (value) => {
    setdeleteShow(false)
    if (value) {
      handleDelete(
        materials.find(({ object_id }) => object_id === selectedId)?.type ||
          'video',
        selectedId
      )
    }
  }

  const [tempSearch, setTempSearch] = useState('')
  const [search, setSearch] = useState('')
  const [focus, setFocus] = useState(false)
  const [selected, setselected] = useState('')

  const [showEdit, setshowEdit] = useState(false)
  const [editData, seteditData] = useState({})
  useEffect(() => {
    seteditData(
      materials.find(({ object_id }) => object_id === selectedId) || {}
    )
  }, [selectedId])

  const handleDownload = (view_url) => {
    const link = document.createElement('a')
    link.setAttribute('href', view_url)
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <Container className="d-flex flex-column pt-3 h-100">
      <Row className="px-5">
        <Col xs={2} className="d-flex">
          <h5 className="my-auto text-chelonia-light fw-bold">素材管理</h5>
        </Col>
        <Col xs={2} />
        <Col xs={3} className="d-flex justifu-content-end">
          <Form.Select
            className="w-100 h-100"
            aria-label="Default select example"
            onChange={(e) => setselected(e.target.value)}
            value={selected}
          >
            <option value="" className="d-none">
              選擇素材類型
            </option>
            {(allowTypes.length
              ? allowTypes.map(
                  (t) =>
                    ({
                      ta: '轉場動畫',
                      wm: '浮水印',
                      br: '影片邊框',
                      clip: '影片',
                      video: '影片',
                    }[t])
                )
              : ['所有素材', '浮水印', '影片邊框', '轉場動畫', '影片']
            ).map((label, i) => (
              <option key={i} value={label}>
                {label}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs={5} className="d-flex pe-0">
          <InputGroup>
            <Form.Control
              placeholder="輸入關鍵字以搜尋素材或字幕..."
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={tempSearch}
              onChange={(event) => setTempSearch(event.target.value)}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              onKeyDown={(event) => {
                if (
                  event.key === 'Enter' &&
                  !event.nativeEvent.isComposing &&
                  focus
                )
                  setSearch(tempSearch)
              }}
            />
            <Button
              variant="outline-dark"
              id="button-addon2"
              title="搜 尋"
              onClick={() => setSearch(tempSearch)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          {!hasCheckBox && (
            <Button
              className="ms-4 w-50"
              variant="chelonia-dark"
              onClick={() => {
                // setselectedId('')
                setshow(true)
              }}
            >
              新增素材&ensp;
              <FontAwesomeIcon icon={faCirclePlus} />
            </Button>
          )}
        </Col>
      </Row>
      <Row
        className="flex-grow-1 pt-3 pb-5 px-5 h-100"
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
      >
        {materials && materials.length ? (
          <ListGroup className="pe-0 h-100">
            {materials
              .filter(({ name, description, type, object_id }) => {
                const attributes = description
                  ? {
                      name,
                      ...parseJSON(description, {
                        size: '',
                        created_on: '',
                        created_by: '',
                      }),
                    }
                  : {
                      name,
                    }
                return (
                  (!search ||
                    Object.keys(attributes).some(
                      (key) =>
                        attributes[key] &&
                        `${attributes[key]}`
                          .toLowerCase()
                          .includes(search.toLowerCase())
                    ) ||
                    subtitle
                      .filter(
                        (s) =>
                          s &&
                          s.text.toLowerCase().includes(search.toLowerCase())
                      )
                      .find((s) => s.source_video === object_id)) &&
                  (!selected ||
                    selected === '所有素材' ||
                    {
                      ta: '轉場動畫',
                      wm: '浮水印',
                      clip: '影片',
                      video: '影片',
                      br: '影片邊框',
                    }[type] === selected) &&
                  (!allowTypes.length || allowTypes.includes(type))
                )
              })
              .map(
                ({
                  name,
                  // transition_animation_id,
                  // watermark_id,
                  // clip_id,
                  object_id,
                  view_url,
                  // created_on,
                  type,
                  // user_name,
                  description,
                }) => {
                  const subs = subtitle.filter(
                    (s) => s && s.source_video === object_id
                  )
                  return (
                    <ListGroupItem className="d-flex row" key={object_id}>
                      {hasCheckBox && (
                        <Col className="d-flex" xs={1}>
                          <Form.Check
                            className="m-auto ps-2 pe-4"
                            checked={selectedMaterial[object_id]}
                            onChange={() => handleSelect(object_id)}
                          />
                        </Col>
                      )}
                      <Col xs={2} style={{ height: '5rem' }}>
                        {['ta', 'clip', 'video'].includes(type) ? (
                          <video className="m-auto p-1 w-auto h-100" controls>
                            <track
                              style={{ overflow: 'hidden' }}
                              kind="captions"
                            />
                            <source src={view_url} />
                          </video>
                        ) : (
                          <div
                            className="w-100 h-100 py-2"
                            style={{ overflowY: 'hidden' }}
                          >
                            <Image
                              src={view_url}
                              className="m-auto pe-2 w-auto h-100"
                            />
                          </div>
                        )}
                      </Col>
                      <Col
                        xs={2}
                        className="my-auto text-start oneLineEllipsis fs-7"
                        title={name}
                      >
                        {name}
                      </Col>
                      <Col xs={3} className="my-auto text-start ps-2">
                        <div className="fs-7 fw-regular text-chelonia">
                          檔案大小｜
                          {description
                            ? parseJSON(description, {
                                size: '',
                                created_on: '',
                                created_by: '',
                              }).size
                            : ''}
                        </div>
                        <div className="fs-7 fw-regular text-chelonia">
                          類型｜
                          {
                            {
                              ta: '轉場動畫',
                              wm: '浮水印',
                              br: '影片邊框',
                              clip: '影片',
                              video: '影片',
                            }[type]
                          }
                        </div>
                        <div className="fs-7 fw-regular text-chelonia">
                          建立者｜
                          {description
                            ? parseJSON(description, {
                                size: '',
                                created_on: '',
                                created_by: '',
                              }).created_by
                            : ''}
                        </div>
                        <div className="fs-7 fw-regular text-chelonia">
                          建立時間｜
                          {description
                            ? parseJSON(description, {
                                size: '',
                                created_on: '',
                                created_by: '',
                              }).created_on
                            : ''}
                        </div>
                      </Col>
                      <Col xs={3} className="my-auto text-start ps-2">
                        {subs.slice(0, 5).map((sub) => (
                          <div
                            key={sub.object_id}
                            className="my-auto text-start oneLineEllipsis fs-7 fw-regular text-chelonia"
                          >
                            {sub.text}
                          </div>
                        ))}
                      </Col>
                      {hasTools ? (
                        <Col xs={2} className="d-flex my-auto">
                          {['clip', 'video'].includes(type) ? (
                            <>
                              <Button
                                className="ms-auto"
                                style={{ boxShadow: 'none' }}
                                variant="edit"
                                onClick={() => {
                                  setselectedId(object_id)
                                  setshowVideoEditor(true)
                                }}
                                title="編 輯 素 材"
                              >
                                <FontAwesomeIcon icon={faClapperboard} />
                              </Button>
                              <h2
                                className="my-auto text-grey"
                                style={{ userSelect: 'none' }}
                              >
                                ｜
                              </h2>
                            </>
                          ) : (
                            <div className="ms-auto" />
                          )}
                          <Button
                            className=""
                            style={{ boxShadow: 'none' }}
                            variant="edit"
                            onClick={() => {
                              setselectedId(object_id)
                              setshowEdit(true)
                            }}
                            title="重 新 命 名"
                            size
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </Button>
                          <Button
                            style={{ boxShadow: 'none' }}
                            title="下 載"
                            variant="edit"
                            onClick={() => handleDownload(view_url)}
                          >
                            <FontAwesomeIcon icon={faDownload} />
                          </Button>
                          <Button
                            style={{ boxShadow: 'none' }}
                            variant="red"
                            onClick={() => {
                              console.log(object_id)
                              setselectedId(object_id)
                              setdeleteShow(true)
                            }}
                            title="刪 除"
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </Button>
                        </Col>
                      ) : (
                        <Col xs={1} />
                      )}
                    </ListGroupItem>
                  )
                }
              )}
          </ListGroup>
        ) : (
          <div className="d-flex ps-3 border">
            <h5 className="m-auto text-chelonia-light">目前尚無資料</h5>
          </div>
        )}
      </Row>
      <ProjectModal
        setting={{
          show,
          form,
          // duration,
          setDuration,
          // defaultValue: selectedId
          //   ? list.find((l) => l.material_id === selectedId)
          //   : {},
          data,
          onDataChange,
          defaultValue: {},
          handleClose,
        }}
      />
      <DeleteModal
        setting={{
          show: deleteShow,
          name: materials.find(({ object_id }) => object_id === selectedId)
            ?.name,
          handleClose: handleDeleteClose,
        }}
      />
      {warn.show && <ClipEndModal setting={warn} />}
      {showVideoEditor && (
        <VideoEditorModal
          setting={{
            material_id: selectedId,
            setmaterial_id: setselectedId,
            handleClipEnd: (id) => {
              setWarn({
                show: true,
                text: '剪輯完成',
                handleClose: (value) => {
                  setWarn({
                    ...warn,
                    show: false,
                  })
                  if (value) {
                    setselectedId(id)
                    setshowVideoEditor(true)
                  }
                },
              })
            },
            show: showVideoEditor,
            setshow: setshowVideoEditor,
            handleClose: () => {
              setshowVideoEditor(false)
            },
          }}
        />
      )}
      {selectedId && showEdit && (
        <Modal
          style={{ zIndex: '1502' }}
          show={showEdit}
          handleClose={() => setshowEdit(false)}
        >
          <Modal.Header className="h5 text-chelonia">編輯素材</Modal.Header>
          <Modal.Body className="p-4">
            <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
              素材名稱
            </Form.Label>

            <Form.Control
              type="text"
              value={editData.name}
              onChange={(e) =>
                seteditData({
                  ...editData,
                  name: e.target.value,
                })
              }
              placeholder="輸入名稱"
            />
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button
              className="ms-auto me-2"
              style={{ boxShadow: 'none' }}
              variant="secondary"
              onClick={() => setshowEdit(false)}
            >
              取 消
            </Button>
            <Button
              className="me-auto"
              style={{ boxShadow: 'none' }}
              variant="chelonia-dark"
              onClick={() => {
                setshowEdit(false)
                handleModify(editData.type, selectedId, {
                  name: editData.name,
                })
              }}
            >
              確 認
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  )
}

MaterialList.propTypes = {
  setting: PropTypes.shape().isRequired,
}

DeleteModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

ProjectModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default MaterialList

/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilm,
  // faBrush,
  faSpinner,
  faVectorSquare,
} from '@fortawesome/free-solid-svg-icons'
import {
  Row,
  Col,
  Button,
  Form,
  Modal,
  Card,
  Dropdown,
  Tabs,
  Tab,
  Spinner,
  InputGroup,
} from 'react-bootstrap'
import { set } from 'date-fns'
import { UploaderContext, AuthContext } from './ContextProvider'
import AudioVisualizer from './AudioVisualizer'
import apiServices from '../services/apiServices'

const now = new Date()
const getTodayAtSpecificHour = (hour = 12) =>
  set(now, { hours: hour, minutes: 0, seconds: 0, milliseconds: 0 })
const selectedStart = getTodayAtSpecificHour(5)
const selectedEnd = getTodayAtSpecificHour(15)

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <Button
    size="sm"
    className="mt-1 ms-auto dropdown-toggle btn"
    variant="outline-secondary"
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
    ref={ref}
  >
    AI自動生成&ensp;
    <FontAwesomeIcon icon={faSpinner} />
  </Button>
))

function VideoEditorModal({ setting }) {
  const { show, setshow, handleClose, material_id, handleClipEnd } = setting
  const { auth } = useContext(AuthContext)
  const audioElmRef = useRef(null)
  const [tempFile, settempFile] = useState(null)
  const [video, setvideo] = useState({})
  const [clipping, setclipping] = useState(false)

  const { materials, videos, handleSilentUpload, handleModify } =
    useContext(UploaderContext)
  useEffect(() => {
    if (tempFile) {
      // handleUpload(video_id, tempFile, (v) => {
      //   setvideo(v)
      // })
    }
  }, [tempFile])

  useEffect(() => {
    if (show && material_id) {
      let target = materials.find((v) => v.object_id === material_id)
      if (!target) {
        target = videos.find((v) => v.object_id === material_id)
      }
      setvideo(target)
    }
  }, [show, material_id])

  // const handleVideoClear = async () => {
  // const newVideo = await apiServices.data({
  //   path: `video/${video_id}`,
  //   method: 'put',
  //   data: {
  //     uploadedVideo: '',
  //   },
  // })
  // setvideo(newVideo)
  // }

  const [generating, setgenerating] = useState({
    subline: false,
    clip: false,
  })

  const [subtitle, setsubtitle] = useState([])
  const getSubtitle = async (offset = 0) => {
    const res = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/subtitle/management/?source_video=${material_id}&limit=100&offset=${offset}`,
      method: 'get',
    })
    if (res.count && res.count >= offset + 100) {
      const res2 = await getSubtitle(offset + 100)
      return res.data.concat(res2)
    }
    return res.data
  }
  const refreshSubtitle = async () => {
    const res = await getSubtitle()
    console.log(res)
    if (res.length) {
      setsubtitle(res)
    }
  }
  useEffect(() => {
    if (material_id) refreshSubtitle()
  }, [material_id])

  const handleSubline = async (language) => {
    setgenerating({
      ...generating,
      subline: true,
    })
    // const { duration } = audioElmRef.current
    // const exists = await apiServices.data({
    //   path: 'worker/subtitle/subtitle-generation-task/',
    //   method: 'get',
    // })
    // if (exists.data && exists.data[0]) {
    //   await apiServices.data({
    //     path: `worker/subtitle/generation-task/${exists.data[0].object_id}/`,
    //     method: 'delete',
    //   })
    // }
    const created = await apiServices.data({
      // path: `video/management/${video_id}/subtitle-generation-task/`,
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/subtitle/generation-task/`,
      method: 'post',
      data: {
        language,
        // prompt: 'This is a speech',
        // temperature: 0.1,
        source_video: material_id,
      },
    })
    console.log(created)
    const { object_id } = created.data
    // await apiServices.data({
    //   path: `worker/subtitle/subtitle-generation-task/${listed.data[0].task_id}/`,
    //   method: 'patch',
    //   data: {
    //     task_status: 'PROCESSING',
    //   },
    // })
    const getStatus = async (clearFunc) => {
      const task = await apiServices.data({
        // path: `video/management/${video_id}/subtitle-generation-task/`,
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/footage/subtitle/generation-task/?source_video=${material_id}`,
        method: 'get',
      })
      const target = task.data.find((d) => d.object_id === object_id)
      console.log(target)
      if (target && target.task_status === 'FINISHED') {
        await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/footage/subtitle/generation-task/${created.data.object_id}/`,
          method: 'delete',
        })
        refreshSubtitle()
        clearFunc()
        setgenerating({
          ...generating,
          subline: false,
        })
      }
    }
    getStatus()
    const interval = setInterval(() => {
      getStatus(() => clearInterval(interval))
    }, 5000)
  }

  const [cliper, setCliper] = useState([-1, -1])
  const handleClip = async () => {
    if (cliper.includes(-1)) {
      // setWarn({
      //   show: true,
      //   text: '請先選擇片段',
      //   handleClose: () =>
      //     setWarn({
      //       ...warn,
      //       show: false,
      //     }),
      // })
      return
    }
    // const cliped = await apiServices.data({
    //   path: `video/edit/${video_id}/clip`,
    //   method: 'post',
    //   data: {
    //     start: video.setting?.subline[cliper[0]].start,
    //     end: video.setting?.subline[cliper[1]].end,
    //   },
    // })
    // setvideo(cliped)
    setclipping(true)
    console.log('clip start')
    // const start = parseInt(
    //   duration *
    //     (moment(selectedInterval[0]).diff(moment().startOf('day'), 'seconds') /
    //       max),
    //   10
    // )
    // const end = parseInt(
    //   duration *
    //     (moment(selectedInterval[1]).diff(moment().startOf('day'), 'seconds') /
    //       max),
    //   10
    // )
    const { start } = subtitle
      .filter((s) => s)
      .filter(
        ({ text, source_video }) =>
          !['ddc', 'cc'].includes(text) && source_video === material_id
      )[cliper[0]]
    const { end } = subtitle
      .filter((s) => s)
      .filter(
        ({ text, source_video }) =>
          !['ddc', 'cc'].includes(text) && source_video === material_id
      )[cliper[1]]
    const created = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/clip/management/`,
      method: 'post',
      data: {
        source_video: material_id,
        start,
        end,
        name: `clip`,
        description: JSON.stringify({
          created_on: moment().format('yyyy-MM-DD'),
          created_by: auth.username,
        }),
      },
    })
    // getClips()
    console.log(created)
    await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/clip/management/${created.data.object_id}/exporting-task/`,
      method: 'post',
    })
    const getStatus = async (clearFunc) => {
      const exported = await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/footage/clip/management/${created.data.object_id}/exporting-task/`,
        method: 'get',
      })
      if (
        exported.data &&
        exported.data[0] &&
        exported.data[0].task_status === 'FINISHED'
      ) {
        await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/footage/clip/management/${created.data.object_id}/exporting-task/${
            exported.data.object_id
          }`,
          method: 'delete',
        })
        const exportedClip = await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/footage/clip/management/${
            created.data.object_id
          }/exported/?limit=100`,
          method: 'get',
        })
        if (
          exportedClip.data &&
          exportedClip.data[0] &&
          exportedClip.data[0].view_url
        ) {
          console.log('download to reupload')
          const videoData = await apiServices.extenal({
            url: exportedClip.data[0].view_url,
            method: 'get',
            responseType: 'arraybuffer',
          })
          const blob = new Blob([videoData.data])
          const file = new File([blob], video.name)
          console.log('download end, start to reupload')
          handleSilentUpload('video', file, async (v) => {
            console.log(v)
            await handleModify('video', v.object_id || '', {
              description: JSON.stringify({
                type: 'video',
                size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
                duration: end - start,
                created_on: moment().format('yyyy-MM-DD HH:mm:ss'),
                created_by: auth.username,
              }),
            })
            console.log('modify end')
            handleClipEnd(v.object_id)
            // description: JSON.stringify({
            //   created_on: moment().format('yyyy-MM-DD'),
            //   created_by: auth.username,
            // }),
            // setvideo(v)
            setclipping(false)
            setshow(false)
          })
        }

        clearFunc()
      }
    }

    getStatus()
    const interval = setInterval(() => {
      getStatus(() => {
        clearInterval(interval)
      })
    }, 5000)
  }

  const [tab, setTab] = useState('audio-visualize')
  const [selectedInterval, setSelectedInterval] = useState([
    selectedStart,
    selectedEnd,
  ])

  const getClipRange = async () => {
    setclipping(true)
    console.log('clip start')
    const { duration } = audioElmRef.current
    const max = 3600 * 20
    const start = parseInt(
      duration *
        (moment(selectedInterval[0]).diff(moment().startOf('day'), 'seconds') /
          max),
      10
    )
    const end = parseInt(
      duration *
        (moment(selectedInterval[1]).diff(moment().startOf('day'), 'seconds') /
          max),
      10
    )
    const created = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/clip/management/`,
      method: 'post',
      data: {
        source_video: material_id,
        start,
        end,
        name: `clip`,
        description: JSON.stringify({
          created_on: moment().format('yyyy-MM-DD'),
          created_by: auth.username,
        }),
      },
    })
    // getClips()
    console.log(created)
    await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/clip/management/${created.data.object_id}/exporting-task/`,
      method: 'post',
    })
    const getStatus = async (clearFunc) => {
      const exported = await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/footage/clip/management/${created.data.object_id}/exporting-task/`,
        method: 'get',
      })
      if (
        exported.data &&
        exported.data[0] &&
        exported.data[0].task_status === 'FINISHED'
      ) {
        await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/footage/clip/management/${created.data.object_id}/exporting-task/${
            exported.data.object_id
          }`,
          method: 'delete',
        })
        const exportedClip = await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/footage/clip/management/${
            created.data.object_id
          }/exported/?limit=100`,
          method: 'get',
        })
        if (
          exportedClip.data &&
          exportedClip.data[0] &&
          exportedClip.data[0].view_url
        ) {
          console.log('download to reupload')
          const videoData = await apiServices.extenal({
            url: exportedClip.data[0].view_url,
            method: 'get',
            responseType: 'arraybuffer',
          })
          const blob = new Blob([videoData.data])
          const file = new File([blob], video.name)
          console.log('download end, start to reupload')
          handleSilentUpload('video', file, async (v) => {
            console.log(v)
            await handleModify('video', v.object_id || '', {
              description: JSON.stringify({
                type: 'video',
                size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
                duration: end - start,
                created_on: moment().format('yyyy-MM-DD HH:mm:ss'),
                created_by: auth.username,
              }),
            })
            console.log('modify end')
            handleClipEnd(v.object_id)
            // description: JSON.stringify({
            //   created_on: moment().format('yyyy-MM-DD'),
            //   created_by: auth.username,
            // }),
            // setvideo(v)
            setclipping(false)
            setshow(false)
          })
        }

        clearFunc()
      }
    }

    getStatus()
    const interval = setInterval(() => {
      getStatus(() => {
        clearInterval(interval)
      })
    }, 5000)
  }

  const formatTime = (second) => {
    if (!second) return '00:00:00'
    return new Date(parseFloat(second) * 1000).toISOString().substr(11, 8)
  }

  const [playbackRate, setplaybackRate] = useState('')
  useEffect(() => {
    if (audioElmRef.current && playbackRate) {
      audioElmRef.current.playbackRate = playbackRate
    }
  }, [playbackRate])

  const [search, setSearch] = useState('')
  // const [tempSearch, setTempSearch] = useState('')
  // const [focus, setFocus] = useState(false)
  const [currentTime, setcurrentTime] = useState(-1)
  const [editingSub, seteditingSub] = useState('')
  const [tempSub, settempSub] = useState('')

  const handleSubChange = async () => {
    const sub = subtitle.find((s) => s.object_id === editingSub)
    const res = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/subtitle/management/${editingSub}/`,
      method: 'patch',
      data: {
        start: sub.start,
        end: sub.end,
        text: tempSub,
      },
    })
    console.log(res)
    if (!res.error) {
      setsubtitle(
        subtitle.map((s) =>
          s.object_id === editingSub
            ? {
                ...s,
                text: tempSub,
              }
            : s
        )
      )
    }
    seteditingSub('')
    settempSub('')
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      size="xl"
      onHide={() => handleClose()}
      className="py-2 px-4"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h5>素材編輯</h5>
      </Modal.Header>
      <Modal.Body
        className="p-4 d-flex flex-column"
        style={{
          height: '73vh',
        }}
      >
        {clipping ? (
          <h5 className="text-grey m-auto d-flex justify-content-center">
            <Spinner size="sm" className="me-2 my-auto" />
            片段產生中...
          </h5>
        ) : (
          <>
            <Card className="d-flex w-100" style={{ height: '52%' }}>
              <Form.Control
                className="p-0 m-0 border-0"
                id="file"
                name="file"
                type="file"
                multiple
                accept="video/*"
                // value={fileList}
                onChange={(e) => {
                  // setuploading(true)
                  settempFile(e.target.files[0])
                  e.target.value = null
                }}
                style={{
                  visibility: 'hidden',
                  height: 0,
                }}
              />
              {video && video.view_url ? (
                <Col className="w-100 h-100 d-flex bg-black flex-column">
                  <video
                    width="auto"
                    height="100%"
                    className="m-auto"
                    controls
                    ref={audioElmRef}
                    crossOrigin="anonymous"
                    onTimeUpdate={(e) => {
                      setcurrentTime(e.nativeEvent.target.currentTime || -1)
                      console.log(e)
                    }}
                  >
                    <track kind="captions" />
                    <source src={video.view_url} />
                  </video>
                </Col>
              ) : (
                <Form.Label
                  htmlFor="file"
                  className="d-flex h-100 w-100"
                  style={{ cursor: 'pointer' }}
                  title="影 片 上 傳"
                >
                  <FontAwesomeIcon
                    icon={faFilm}
                    className="text-grey h-25 m-auto"
                  />
                </Form.Label>
              )}
            </Card>
            {/* Toolbar */}
            <Card
              className="w-100 mt-2"
              style={{
                height: '9%',
                backgroundColor: 'rgb(173, 185, 197, 0.5)',
              }}
            >
              <Row className="my-auto ps-2 w-100">
                <Col xs={1} />
                <Col xs={5} className="d-flex">
                  <Button
                    size="sm"
                    className="px-2"
                    style={{ boxShadow: 'none' }}
                    onClick={() => {
                      console.log(audioElmRef.current)
                      if (audioElmRef.current) {
                        console.log(audioElmRef.current.currentTime)
                        if (audioElmRef.current.currentTime > 5)
                          audioElmRef.current.currentTime -= 5
                        else audioElmRef.current.currentTime = 0
                      }
                    }}
                    variant="light"
                    title="-5s"
                  >
                    -5s
                  </Button>
                  <Button
                    size="sm"
                    className="px-2 ms-1 me-3"
                    style={{ boxShadow: 'none' }}
                    variant="light"
                    onClick={() => {
                      if (audioElmRef.current) {
                        if (audioElmRef.current.currentTime > 1)
                          audioElmRef.current.currentTime -= 1
                        else audioElmRef.current.currentTime = 0
                      }
                    }}
                    title="-1s"
                  >
                    -1s
                  </Button>

                  <Button
                    size="sm"
                    className="px-2 ms-auto"
                    style={{ boxShadow: 'none' }}
                    variant="light"
                    title="+1s"
                    onClick={() => {
                      if (audioElmRef.current) {
                        if (
                          audioElmRef.current.currentTime <
                          audioElmRef.current.duration - 1
                        )
                          audioElmRef.current.currentTime += 1
                        else
                          audioElmRef.current.currentTime =
                            audioElmRef.current.duration
                      }
                    }}
                  >
                    +1s
                  </Button>
                  <Button
                    size="sm"
                    className="px-2 ms-1"
                    style={{ boxShadow: 'none' }}
                    variant="light"
                    title="+5s"
                    onClick={() => {
                      if (audioElmRef.current) {
                        if (
                          audioElmRef.current.currentTime <
                          audioElmRef.current.duration - 5
                        )
                          audioElmRef.current.currentTime += 5
                        else
                          audioElmRef.current.currentTime =
                            audioElmRef.current.duration
                      }
                    }}
                  >
                    +5s
                  </Button>
                </Col>
                <Col xs={2} />
                <Col xs={2} className="d-flex pe-0 ms-auto">
                  <Form.Select
                    name="pic"
                    className="my-auto ms-auto"
                    aria-label="Default select example2"
                    onChange={(e) => setplaybackRate(e.target.value)}
                    value={playbackRate}
                    size="sm"
                  >
                    <option value="" className="d-none">
                      播放速度
                    </option>
                    {[
                      { label: 'x0.25', value: 0.25 },
                      { label: 'x0.5', value: 0.5 },
                      { label: 'x0.75', value: 0.75 },
                      { label: '正常速度', value: 1 },
                      { label: 'x1.25', value: 1.25 },
                      { label: 'x1.5', value: 1.5 },
                      { label: 'x1.75', value: 1.75 },
                      { label: 'x2.0', value: 2 },
                    ].map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                {/* <Col xs={2} className="my-auto ms-auto pe-0">
                  <Form.Select
                    name="size"
                    className="my-auto ms-auto"
                    aria-label="Default select example3"
                    // onChange={handleSelected}
                    // value={selected.pic}
                    size="sm"
                  >
                    <option value="" className="d-none2">
                      影片比例
                    </option>
                    {[
                      'Horizontal (16:9)',
                      'Vertical (9:16)',
                      'Square (1:1)',
                      'Social (4:5)',
                    ].map((label, i) => (
                      <option key={i} value={label}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                </Col> */}
              </Row>
            </Card>
            <Card className="w-100 mt-2" style={{ height: '40%' }}>
              <Row className="h-100 d-flex flex-column">
                <div
                  className="position-absolute d-flex pe-0"
                  style={{ width: '63%', top: '0%', left: '36%' }}
                >
                  {tab !== 'audio-visualize' ? (
                    <>
                      <InputGroup className="ms-auto my-auto h-75">
                        <Form.Control
                          placeholder="輸入關鍵字以搜尋字幕..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                        />
                      </InputGroup>
                      <Dropdown className="ms-2">
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropdown-custom-components"
                        />
                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-2"
                            onClick={() => handleSubline('zh')}
                          >
                            中文
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-3"
                            onClick={() => handleSubline('en')}
                          >
                            English
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <Button
                        size="sm"
                        className="my-auto ms-1 text-nowrap"
                        variant="outline-secondary"
                      >
                        套用字幕&ensp;
                        <FontAwesomeIcon icon={faBrush} />
                      </Button> */}
                      <Button
                        size="sm"
                        className="my-auto ms-1 text-nowrap"
                        variant="outline-secondary"
                        onClick={handleClip}
                      >
                        擷取片段&ensp;
                        <FontAwesomeIcon icon={faVectorSquare} />
                      </Button>
                    </>
                  ) : (
                    <Button
                      size="sm"
                      className="mt-1 ms-auto"
                      variant="outline-secondary"
                      onClick={getClipRange}
                    >
                      擷取片段&ensp;
                      <FontAwesomeIcon icon={faVectorSquare} />
                    </Button>
                  )}
                </div>
                <Col className="h-100 d-flex flex-column" xs={12}>
                  <Tabs
                    defaultActiveKey="audio-visualize"
                    id="ai_subtitle"
                    className="mb-2"
                    onSelect={(e) => {
                      setTab(e)
                    }}
                  >
                    <Tab
                      className="w-100 h-100"
                      eventKey="audio-visualize"
                      title="音頻擷取"
                    >
                      <AudioVisualizer
                        setting={{
                          show: tab === 'audio-visualize',
                          audioElmRef,
                          audioUrl: video && video.view_url,
                          selectedInterval,
                          setSelectedInterval,
                        }}
                      />
                    </Tab>

                    <Tab
                      className="w-100 h-100"
                      eventKey="ai_subtitle"
                      title="自動字幕"
                    >
                      {generating.subline ? (
                        <>
                          <br />
                          <br />
                          <br />
                          <h5 className="text-grey m-auto d-flex justify-content-center">
                            <Spinner size="sm" className="me-2 my-auto" />
                            字幕生成中...
                          </h5>
                        </>
                      ) : subtitle.length ? (
                        subtitle
                          .filter((s) => s)
                          .filter(
                            ({ text, source_video }) =>
                              !['ddc', 'cc'].includes(text) &&
                              source_video === material_id
                          )
                          .map(({ start, end, text, object_id }, i) => (
                            <Row
                              key={i}
                              className="text-start text-nowrap ps-3"
                              style={{
                                cursor: 'pointer',
                                backgroundColor: (
                                  cliper.every((c) => c !== -1)
                                    ? i <= cliper[1] && i >= cliper[0]
                                    : cliper.includes(i)
                                )
                                  ? '#cddfed'
                                  : 'white',
                                color:
                                  start < currentTime && end > currentTime
                                    ? 'red'
                                    : '',
                              }}
                              onClick={() => {
                                if (cliper.includes(i))
                                  setCliper(
                                    cliper.map((c) => (c === i ? -1 : c))
                                  )
                                else if (i > cliper[1])
                                  setCliper([
                                    cliper[0] === -1 ? cliper[1] : cliper[0],
                                    i,
                                  ])
                                else setCliper([i, cliper[1]])
                              }}
                            >
                              <Col className="ps-2" xs={2}>
                                {formatTime(start)}
                              </Col>
                              <Col className="ps-2" xs={2}>
                                {formatTime(end)}
                              </Col>
                              {object_id === editingSub ? (
                                <Col
                                  className="ps-2 oneLineEllipsis"
                                  title={text}
                                  xs={8}
                                >
                                  <Form.Control
                                    value={tempSub}
                                    onChange={(e) => settempSub(e.target.value)}
                                    onBlur={handleSubChange}
                                    onKeyDown={(event) => {
                                      if (
                                        event.key === 'Enter' &&
                                        !event.nativeEvent.isComposing
                                      )
                                        handleSubChange()
                                    }}
                                  />
                                </Col>
                              ) : (
                                <Col
                                  className="ps-2 oneLineEllipsis"
                                  title={text}
                                  xs={8}
                                  onClick={() => {
                                    seteditingSub(object_id)
                                    settempSub(text)
                                  }}
                                  style={{
                                    pointer: 'cursor',
                                  }}
                                >
                                  {search
                                    ? text
                                        .split(new RegExp(`(${search})`, 'g'))
                                        .map((p) =>
                                          p === search ? (
                                            <span
                                              style={{
                                                color: 'white',
                                                backgroundColor: 'black',
                                              }}
                                            >
                                              {p}
                                            </span>
                                          ) : (
                                            p
                                          )
                                        )
                                    : text}
                                </Col>
                              )}
                            </Row>
                          ))
                      ) : (
                        <div className="d-flex h-100">
                          <h5 className="text-grey m-auto">目前尚無資料</h5>
                        </div>
                      )}
                    </Tab>

                    {/* <Tab
                      className="w-100 h-100"
                      eventKey="fix_subtitle"
                      title="字幕修正"
                    /> */}
                  </Tabs>
                </Col>
              </Row>
            </Card>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto me-2"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose(true)}
        >
          確 認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

VideoEditorModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default VideoEditorModal

/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-promise-executor-return */
import React, { useState, useEffect, useMemo, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactCrop from 'react-image-crop'
import {
  faBars,
  faCirclePlus,
  faFilm,
  faScissors,
  // faSpinner,
  faDownload,
  faCircleMinus,
  faEye,
  faTrashCan,
  faCirclePlay,
  faCircleInfo,
  faCheckCircle,
  faTimes,
  faCircleUser,
} from '@fortawesome/free-solid-svg-icons'
import { faSquareYoutube, faYoutube } from '@fortawesome/free-brands-svg-icons'
import {
  Row,
  Card,
  Container,
  Col,
  Form,
  FormLabel,
  Button,
  Modal,
  Spinner,
  ListGroupItem,
  InputGroup,
  Badge,
} from 'react-bootstrap'
import moment from 'moment'
// import { set } from 'date-fns'
// import TimeRange from 'react-video-timelines-slider'
// import { format } from 'date-fns'
import Warn from './Warn'
// import { CNLogo2 } from '../assets'
import apiServices from '../services/apiServices'
import { UploaderContext, AuthContext, ToastContext } from './ContextProvider'
import MaterialList from './MaterialList'

const parseJSON = (inputString, fallback) => {
  if (inputString) {
    try {
      return JSON.parse(inputString)
    } catch (e) {
      return fallback
    }
  }
  return fallback
}

function Preview({ setting }) {
  const {
    WM,
    WMP,
    subtitles,
    thumbnail,
    top_left_x,
    top_left_y,
    lower_right_x,
    lower_right_y,
  } = setting
  const { materials } = useContext(UploaderContext)

  const scale = 0.63

  // const [crop] = useState({
  //   unit: 'px',
  //   width: (lower_right_x - top_left_x) * scale,
  //   height: (lower_right_y - top_left_y) * scale,
  //   x: top_left_x * scale,
  //   y: top_left_y * scale,
  // })

  // useEffect(() => {
  //   setcrop({
  //     unit: 'px',
  //     width: (lower_right_x - top_left_x) * scale,
  //     height: (lower_right_y - top_left_y) * scale,
  //     x: top_left_x * scale,
  //     y: top_left_y * scale,
  //   })
  // }, [top_left_x, top_left_y, lower_right_x, lower_right_y])

  // const rate =
  //   crop.width > crop.height
  //     ? 9 / 16
  //     : crop.width === crop.height
  //     ? 1 / 1
  //     : 16 / 9

  const [size] = useState(1)

  const src = useMemo(() => {
    const target = materials.find(({ object_id }) => object_id === WM)
    return target ? target.view_url : ''
  }, [WM, materials])

  const imgRef = useRef()
  const [axis, setaxis] = useState({
    x_position: 0,
    y_position: 0,
  })
  useEffect(() => {
    const xy = JSON.parse(WMP)
    setaxis({
      x_position: xy.x_position / scale,
      y_position: xy.y_position / scale,
    })
  }, [WMP])

  console.log(scale * size)

  const [subtitle, setsubtitle] = useState({
    y_position: 0.9,
    font_family: '思源黑體（Noto Sans CJKTC）',
    font_weight: 0,
    font_color: '#FFFFFF',
    font_size: '20px',
    background_color: '無',
    background_opacity: '0%',
  })
  useEffect(() => {
    const temp = {}
    Object.keys(subtitle).forEach((key) => {
      temp[key] = subtitles[key] || subtitle[key]
    })
    setsubtitle(temp)
  }, [subtitles])

  const weightName = {
    0: 'Normal',
    1: 'Black',
    2: 'Bold',
  }

  return (
    <div
      className="border position-relative mx-auto"
      style={{
        width: `${1280 * scale}px`,
        height: `${720 * scale}px`,
      }}
    >
      <div
        className="d-flex position-relative overflow-hidden"
        style={{
          width: `${1280 * scale}px`,
          height: `${720 * scale}px`,
        }}
      >
        <div
          className="position-absolute"
          style={{
            left: `${top_left_x * scale}px`,
            top: `${top_left_y * scale}px`,
            width: `${(lower_right_x - top_left_x) * scale}px`,
            height: `${(lower_right_y - top_left_y) * scale}px`,
            boxShadow: '0 0 0 99999px rgba(0, 0, 0, 1)',
            zIndex: '999',
            pointerEvents: 'none',
          }}
        />
        <div
          className="position-absolute w-100"
          style={{
            top: `${subtitle.y_position * 100}%`,
            left: 0,
            height: `${
              parseInt(subtitle.font_size.replace('px', ''), 10) * 1.5
            }px`,
            backgroundColor: subtitle.background_color,
            color: subtitle.font_color,
            opacity: 1 - subtitle.background_opacity.replace('%', '') * 0.01,
          }}
        />
        <div
          className="position-absolute w-100 text-center"
          style={{
            fontFamily: subtitle.font_family,
            fontSize: subtitle.font_size,
            fontWeight: weightName[subtitle.font_weight],
            top: `${subtitle.y_position * 100}%`,
            left: 0,
            background: 'transparent',
            color: subtitle.font_color,
            backgroundOpacity:
              1 - subtitle.background_opacity.replace('%', '') * 0.01,
          }}
        >
          qwertyuiopasdfghjklzxcvbnm
        </div>
        {thumbnail ? (
          <video
            className="m-auto h-100 w-100"
            src={thumbnail}
            crossOrigin="anonymous"
            controls
          >
            <track kind="captions" />
          </video>
        ) : (
          <FormLabel
            className="d-flex h-100 w-100"
            style={{ cursor: 'pointer' }}
            title=""
          >
            <FontAwesomeIcon icon={faFilm} className="text-grey h-20 m-auto" />
          </FormLabel>
        )}
      </div>
      {WM ? (
        <div
          className="w-100 h-100 position-absolute overflow-hidden"
          style={{
            top: 0,
            left: 0,
            pointerEvents: 'none',
          }}
        >
          <img
            src={src}
            alt=""
            ref={imgRef}
            className="position-absolute"
            draggable="true"
            style={{
              left: `${axis.x_position || '0'}px`,
              top: `${axis.y_position || '0'}px`,
              scale: scale * size,
              transform: `scale(${scale * size})`,
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

function CropModal({ setting }) {
  const {
    show,
    dimension,
    top_left_x,
    top_left_y,
    lower_right_x,
    lower_right_y,
    src,
    thumbnail,
    handleClose,
  } = setting

  const scale = Math.min(
    0.7,
    Math.min((1280 * 0.7) / dimension.width, (720 * 0.7) / dimension.height)
  )

  // const imgRef = useRef()
  // const [differ, setdiffer] = useState({
  //   x_position: 0,
  //   y_position: 0,
  //   width: 0,
  //   height: 0,
  // })
  // const [axis, setaxis] = useState({
  //   top_left_x,
  //   top_left_y,
  //   lower_right_x,
  //   lower_right_y,
  // })
  const [crop, setcrop] = useState({
    unit: 'px',
    width: (lower_right_x - top_left_x) * scale,
    height: (lower_right_y - top_left_y) * scale,
    x: top_left_x * scale,
    y: top_left_y * scale,
  })

  const [rate, setrate] = useState(
    crop.width > crop.height
      ? 16 / 9
      : crop.width === crop.height
      ? 1 / 1
      : 9 / 16
  )

  // useEffect(() => {
  //   if (show)
  //     setcrop({
  //       unit: '%',
  //       width: lower_right_x - top_left_x,
  //       height: lower_right_y - top_left_y,
  //       x: top_left_x,
  //       y: top_left_y,
  //     })
  // }, [show])

  const handleRateChange = (newRate) => {
    setrate(newRate)
    // const width = dimension.width * scale
    // const height = dimension.height * scale
    // const newcrop = centerCrop(
    //   makeAspectCrop(
    //     {
    //       // You don't need to pass a complete crop into
    //       // makeAspectCrop or centerCrop.
    //       unit: 'px',
    //       width: lower_right_x - top_left_x,
    //     },
    //     newRate,
    //     width,
    //     height
    //   ),
    //   width,
    //   height
    // )
    const newcrop = {
      unit: 'px',
      width: (lower_right_x - top_left_x) * scale,
      height: (lower_right_y - top_left_y) * scale,
      x: top_left_x * scale,
      y: top_left_y * scale,
    }

    setcrop(newcrop)
  }

  const handleReset = (newRate) => {
    setrate(newRate)
    const width = dimension.width * scale
    const height = dimension.height * scale
    let newcrop = {
      unit: 'px',
      width: width * scale,
      height: height * scale,
      x: 0,
      y: 0,
    }
    console.log(newRate)
    if (newRate > 1) {
      newcrop = {
        unit: 'px',
        width,
        height: width * (9 / 16),
        x: 0,
        y: 0,
      }
    } else if (newRate < 1) {
      newcrop = {
        unit: 'px',
        width: height * (9 / 16),
        height,
        x: 0,
        y: 0,
      }
    } else {
      console.log('1 : 1')
      console.log({
        unit: 'px',
        width,
        height: width,
        x: 0,
        y: 0,
      })
      newcrop = {
        unit: 'px',
        width,
        height: width,
        x: 0,
        y: 0,
      }
    }
    // const newcrop = centerCrop(
    //   makeAspectCrop(
    //     {
    //       // You don't need to pass a complete crop into
    //       // makeAspectCrop or centerCrop.
    //       unit: 'px',
    //       width: lower_right_x - top_left_x,
    //     },
    //     newRate,
    //     width,
    //     height
    //   ),
    //   width,
    //   height
    // )

    setcrop(newcrop)
  }

  useEffect(() => {
    if (show) {
      if (!lower_right_x && !lower_right_y) {
        console.log('reset')
        handleReset(rate)
      } else {
        console.log('changing')
        handleRateChange(rate)
      }
    }
  }, [show])

  // const [draging, setdraging] = useState(false)

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="xl"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <Row className="d-flex">
            <h5>調整畫面比例</h5>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column pt-0">
        <Row className="mb-4">
          <Col xs={11}>
            <Form.Select
              name="pic"
              className="my-auto ms-auto"
              aria-label="Default select example2"
              onChange={(e) => handleReset(e.target.value)}
              value={rate}
              size="sm"
            >
              <option value="" className="d-none">
                畫面比例
              </option>
              {[
                { label: '橫式16:9', value: 16 / 9 },
                { label: '直式9:16', value: 9 / 16 },
                { label: '方型1:1', value: 1 / 1 },
              ].map(({ label, value }) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={1} className="m-auto" style={{ cursor: 'help' }}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              title="目前影片預設比例為16:9（1280px＊720px），請參考影片比例上傳對應大小之浮水印。"
            />
          </Col>
        </Row>
        <div
          className="border position-relative mx-auto d-flex justify-content-center"
          style={{
            width: `${dimension.width * scale}px`,
            height: `${dimension.height * scale}px`,
          }}
        >
          <ReactCrop
            crop={crop}
            aspect={rate}
            onChange={(pxCrop) => setcrop(pxCrop)}
          >
            <div
              className="d-flex position-relative"
              style={{
                width: `${dimension.width * scale}px`,
                height: `${dimension.height * scale}px`,
              }}
            >
              {src ? (
                <video
                  className="m-auto h-100 w-100"
                  src={src}
                  crossOrigin="anonymous"
                >
                  <track kind="captions" />
                </video>
              ) : thumbnail ? (
                <video
                  className="m-auto h-100 w-100"
                  src={thumbnail}
                  crossOrigin="anonymous"
                >
                  <track kind="captions" />
                </video>
              ) : (
                <FormLabel
                  className="d-flex h-100 w-100"
                  style={{ cursor: 'pointer' }}
                  title=""
                >
                  <FontAwesomeIcon
                    icon={faFilm}
                    className="text-grey h-20 m-auto"
                  />
                </FormLabel>
              )}
            </div>
          </ReactCrop>
        </div>
      </Modal.Body>
      <Modal.Footer className="sendForm justify-content-center py-3">
        <Button variant="secondary" onClick={() => handleClose(false)}>
          取消
        </Button>
        <Button
          variant="chelonia-dark"
          onClick={() => {
            console.log(crop)
            console.log({
              width: crop.width,
              height: crop.height,
              x: crop.x,
              y: crop.y,
            })
            handleClose(
              {
                width: crop.width,
                height: crop.height,
                x: crop.x,
                y: crop.y,
              },
              scale
            )
          }}
        >
          確定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function WatermarkModal({ setting }) {
  const {
    show,
    dimension,
    WM,
    setWM,
    WMP,
    setWMP,
    handleClose,
    thumbnail,
    top_left_x,
    top_left_y,
    lower_right_x,
    lower_right_y,
  } = setting
  const { materials } = useContext(UploaderContext)

  const scale = Math.min(
    0.7,
    Math.min((1280 * 0.7) / dimension.width, (720 * 0.7) / dimension.height)
  )

  const [crop, setcrop] = useState({
    unit: 'px',
    width: (lower_right_x - top_left_x) * scale,
    height: (lower_right_y - top_left_y) * scale,
    x: top_left_x * scale,
    y: top_left_y * scale,
  })

  const rate =
    crop.width > crop.height
      ? 16 / 9
      : crop.width === crop.height
      ? 1 / 1
      : 9 / 16

  const [size, setsize] = useState(1)

  const src = useMemo(() => {
    const target = materials.find(({ object_id }) => object_id === WM)
    return target ? target.view_url : ''
  }, [WM, materials])

  const imgRef = useRef()
  const [differ, setdiffer] = useState({
    x_position: 0,
    y_position: 0,
    width: 0,
    height: 0,
  })
  const [axis, setaxis] = useState({
    x_position: 0,
    y_position: 0,
  })
  useEffect(() => {
    const xy = JSON.parse(WMP)
    console.log(xy)
    setaxis({
      x_position: xy.x_position || 0,
      y_position: xy.y_position || 0,
    })
  }, [WMP])
  const [draging, setdraging] = useState(false)

  console.log(scale * size)

  const handleRateChange = () => {
    // setrate(newRate)
    const newcrop = {
      unit: 'px',
      width: (lower_right_x - top_left_x) * scale,
      height: (lower_right_y - top_left_y) * scale,
      x: top_left_x * scale,
      y: top_left_y * scale,
    }
    // const width = dimension.width * scale
    // const height = dimension.height * scale
    // const newcrop = centerCrop(
    //   makeAspectCrop(
    //     {
    //       // You don't need to pass a complete crop into
    //       // makeAspectCrop or centerCrop.
    //       unit: 'px',
    //       width: lower_right_x - top_left_x,
    //     },
    //     rate,
    //     width,
    //     height
    //   ),
    //   width,
    //   height
    // )

    setcrop(newcrop)
  }

  useEffect(() => {
    if (show) {
      handleRateChange()
    }
  }, [show])

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="xl"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <Row className="d-flex">
            <h5>選擇浮水印／影片邊框</h5>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column pt-0">
        <Row className="mb-4">
          <Col xs={8}>
            <Form.Select
              name="pic"
              className="w-100 my-2"
              aria-label="Default select example"
              onChange={(e) => setWM(e.target.value)}
              value={WM}
              size="sm"
            >
              <option value="">無浮水印／影片邊框</option>
              {materials
                .filter(({ type }) => ['wm', 'br'].includes(type))
                .map(({ name, object_id }) => (
                  <option key={object_id} value={object_id}>
                    {name}
                  </option>
                ))}
            </Form.Select>
          </Col>
          <Col xs={3}>
            <Form.Select
              name="size"
              className="w-100 my-2"
              aria-label="Default select example"
              onChange={(e) => setsize(e.target.value)}
              value={size}
              size="sm"
            >
              <option value={1}>原圖尺寸</option>
              {[0.75, 0.5, 0.25, 0.1, 0.05].map((s, j) => (
                <option key={j} value={s}>
                  {s * 100}%
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col xs={1} className="m-auto" style={{ cursor: 'help' }}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              title="目前影片預設比例為16:9（1280px＊720px），請參考影片比例上傳對應大小之浮水印。"
            />
          </Col>
        </Row>
        <div className="w-75 mx-auto d-flex">
          <Col xs={2}>
            <Form.Label>X:{parseInt(axis.x_position, 10)}</Form.Label>
          </Col>
          <Col xs={10}>
            <Form.Range
              min="0"
              max={dimension.width}
              value={axis.x_position}
              onChange={(e) =>
                setaxis({
                  ...axis,
                  x_position: e.target.value,
                })
              }
            />
          </Col>
        </div>
        <div className="w-75 mx-auto d-flex">
          <Col xs={2}>
            <Form.Label>Y:{parseInt(axis.y_position, 10)}</Form.Label>
          </Col>
          <Col xs={10}>
            <Form.Range
              min="0"
              max={dimension.height}
              value={axis.y_position}
              onChange={(e) =>
                setaxis({
                  ...axis,
                  y_position: e.target.value,
                })
              }
            />
          </Col>
        </div>
        <div
          className="border position-relative mx-auto"
          style={{
            width: `${dimension.width * scale}px`,
            height: `${dimension.height * scale}px`,
          }}
        >
          <ReactCrop disabled crop={crop} aspect={rate} onChange={() => {}}>
            <div
              className="d-flex position-relative"
              style={{
                width: `${dimension.width * scale}px`,
                height: `${dimension.height * scale}px`,
              }}
            >
              {thumbnail ? (
                <video
                  className="m-auto h-100 w-100"
                  src={thumbnail}
                  crossOrigin="anonymous"
                >
                  <track kind="captions" />
                </video>
              ) : (
                <FormLabel
                  className="d-flex h-100 w-100"
                  style={{ cursor: 'pointer' }}
                  title=""
                >
                  <FontAwesomeIcon
                    icon={faFilm}
                    className="text-grey h-20 m-auto"
                  />
                </FormLabel>
              )}
            </div>
          </ReactCrop>
          {WM ? (
            <>
              <p
                className="position-absolute"
                style={{
                  top: '-28px',
                  left: `${axis.x_position * scale}px`,
                  height: '25px',
                  pointerEvents: 'none',
                }}
              >
                {parseInt(axis.x_position, 10)}
                {/* {parseInt(
                  parseInt(axis.x_position - differ.x_position, 10) / scale,
                  10
                )} */}
              </p>
              <div
                style={{
                  top: '0px',
                  left: `${axis.x_position * scale}px`,
                  width: '1px',
                  border: '1px dashed #000',
                  pointerEvents: 'none',
                  zIndex: '2',
                }}
                className="position-absolute h-100"
              />
              <p
                style={{
                  left: '-28px',
                  top: `${axis.y_position * scale}px`,
                  height: '25px',
                  pointerEvents: 'none',
                }}
                className="position-absolute"
              >
                {parseInt(axis.y_position, 10)}
                {/* {parseInt(
                  parseInt(axis.y_position - differ.y_position, 10) / scale,
                  10
                )} */}
              </p>
              <div
                style={{
                  left: '0px',
                  top: `${axis.y_position * scale}px`,
                  height: '1px',
                  border: '1px dashed #000',
                  pointerEvents: 'none',
                  zIndex: '2',
                }}
                className="position-absolute w-100"
              />
              <div
                className="w-100 h-100 position-absolute overflow-hidden"
                style={{
                  top: 0,
                  left: 0,
                }}
                onDrop={() => {}}
                onDragOver={(e) => {
                  const target = e.target.getBoundingClientRect()
                  const left = e.clientX - target.x
                  const top = e.clientY - target.y
                  setaxis({
                    x_position: Math.min(
                      dimension.width * scale - differ.width,
                      Math.max(left, differ.x_position)
                    ),
                    y_position: Math.min(
                      dimension.height * scale - differ.height,
                      Math.max(top, differ.y_position)
                    ),
                  })
                  e.stopPropagation()
                  e.preventDefault()
                }}
              >
                <img
                  src={src}
                  alt=""
                  ref={imgRef}
                  className="position-absolute"
                  draggable="true"
                  style={{
                    left: `${axis.x_position * scale || '0'}px`,
                    top: `${axis.y_position * scale || '0'}px`,
                    scale: scale * size,
                    transformOrigin: 'left top',
                    transform: `scale(${scale * size})`,
                    // maxHeight: '100px',
                    // maxWidth: '100px',
                    opacity: draging ? '0' : '1',
                    // translate: `-${parseInt(
                    //   (100 * (1 - scale)) / 2,
                    //   10
                    // )}% -${parseInt((100 * (1 - scale)) / 2, 10)}%`,
                    // pointerEvents: draging ? 'none' : 'auto',
                  }}
                  onDragStart={(e) => {
                    const target = e.target.getBoundingClientRect()
                    const left = e.clientX - target.x
                    const top = e.clientY - target.y
                    console.log('---the difference---')
                    console.log(left)
                    console.log(top)
                    setdiffer({
                      x_position: left,
                      y_position: top,
                      width: parseInt(target.width * scale, 10),
                      height: parseInt(target.height * scale, 10),
                    })
                    setdraging(true)
                  }}
                  onDrag={() => {}}
                  onDragEnd={() => {
                    // setWMP(
                    //   JSON.stringify({
                    //     x_position:
                    //       (axis.x_position - differ.x_position) * scale,
                    //     y_position:
                    //       (axis.y_position - differ.y_position) * scale,
                    //   })
                    // )
                    setdiffer({
                      x_position: 0,
                      y_position: 0,
                      width: 0,
                      height: 0,
                    })
                    setdraging(false)
                  }}
                />
              </div>
            </>
          ) : (
            <div />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="sendForm justify-content-center py-3">
        <Button variant="secondary" onClick={() => handleClose(false)}>
          取消
        </Button>
        <Button
          variant="chelonia-dark"
          onClick={() => {
            console.log(axis)
            setWMP(JSON.stringify(axis))
            handleClose(true)
          }}
        >
          確定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function SubtitlesModal({ setting }) {
  const {
    show,
    dimension,
    handleClose,
    subtitles,
    thumbnail,
    top_left_x,
    top_left_y,
    lower_right_x,
    lower_right_y,
  } = setting
  const [subtitle, setsubtitle] = useState({
    y_position: 0.9,
    font_family: '思源黑體（Noto Sans CJKTC）',
    font_weight: 0,
    font_color: '#FFFFFF',
    font_size: '20px',
    background_color: '無',
    background_opacity: '0%',
  })
  useEffect(() => {
    console.log(subtitles)
    const temp = {}
    Object.keys(subtitle).forEach((key) => {
      temp[key] = subtitles[key] || subtitle[key]
    })
    setsubtitle(temp)
  }, [subtitles])

  const scale = Math.min(
    0.55,
    Math.min((1280 * 0.55) / dimension.width, (720 * 0.55) / dimension.height)
  )

  // const scale = 0.55

  const [crop, setcrop] = useState({
    unit: 'px',
    width: (lower_right_x - top_left_x) * scale,
    height: (lower_right_y - top_left_y) * scale,
    x: top_left_x * scale,
    y: top_left_y * scale,
  })
  console.log(crop)

  const rate =
    crop.width > crop.height
      ? 16 / 9
      : crop.width === crop.height
      ? 1 / 1
      : 9 / 16

  const handleRateChange = () => {
    // setrate(newRate)
    // const width = dimension.width * scale
    // const height = dimension.height * scale
    const newcrop = {
      unit: 'px',
      width: (lower_right_x - top_left_x) * scale,
      height: (lower_right_y - top_left_y) * scale,
      x: top_left_x * scale,
      y: top_left_y * scale,
    }
    // const newcrop = centerCrop(
    //   makeAspectCrop(
    //     {
    //       // You don't need to pass a complete crop into
    //       // makeAspectCrop or centerCrop.
    //       unit: 'px',
    //       width: lower_right_x - top_left_x,
    //     },
    //     rate,
    //     width,
    //     height
    //   ),
    //   width,
    //   height
    // )

    setcrop(newcrop)
  }

  useEffect(() => {
    if (show) {
      handleRateChange()
    }
  }, [show])

  // useEffect(() => {
  //   const width = 1280 * scale
  //   const height = 640 * scale
  //   const newcrop = centerCrop(
  //     makeAspectCrop(
  //       {
  //         // You don't need to pass a complete crop into
  //         // makeAspectCrop or centerCrop.
  //         // unit: '%',
  //         width: lower_right_x - top_left_x,
  //       },
  //       rate,
  //       width,
  //       height
  //     ),
  //     width,
  //     height
  //   )

  //   setcrop(newcrop)
  // }, [rate])

  // y_position: 0.9,
  // font_size: 25,
  // font_color: '#3B5291',
  // font_stroke: '2',
  // background_color: '#84DFF6',
  // background_opacity: '0.8',

  const options = {
    font_family: ['思源黑體（Noto Sans CJKTC）'],
    font_weight: [0, 1, 2],
    font_color: [
      '#000000',
      '#FFFFFF',
      '#3B5192',
      '#84DFF6',
      '#FFC431',
      '#DC0008',
    ],
    font_size: ['10px', '20px', '30px', '40px', '50px', '60px'],
    background_color: [
      '無',
      '#000000',
      '#FFFFFF',
      '#3B5192',
      '#84DFF6',
      '#FFC431',
      '#DC0008',
    ],
    background_opacity: ['0%', '25%', '50%', '75%', '100%'],
    shape: ['方塊'],
  }

  const forms = [
    { label: '字型', key: 'font_family' },
    { label: '字型樣式', key: 'font_weight' },
    { label: '字幕顏色', key: 'font_color' },
    { label: '字體大小', key: 'font_size' },
    { label: '背景顏色', key: 'background_color' },
    { label: '背景透明度', key: 'background_opacity' },
  ]

  const colorName = {
    '#000000': '黑色（#000000）',
    '#FFFFFF': '白色（#FFFFFF）',
    '#3B5192': '深藍（#3B5192）',
    '#84DFF6': '淺藍（#84DFF6）',
    '#FFC431': '黃色（#FFC431）',
    '#DC0008': '紅色（#DC0008）',
  }

  const weightName = {
    0: 'Normal',
    1: 'Black',
    2: 'Bold',
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="xl"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <Row className="d-flex">
            <h5>選擇字幕</h5>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column pt-0">
        <Row className="p-3 pt-4 pb-0">
          <Col xs={4} className="px-3">
            {/* <div className="d-flex">
              <Form.Label className="d-flex my-auto w-25">開啟字幕</Form.Label>
              <Form.Check
                type="switch"
                id="custom-switch"
                className="my-2 w-75"
              />
            </div>
            <hr
              className="w-85"
              style={{ border: '1px dashed rgb(85 85 85)', marginTop: '0' }}
            /> */}
            {forms.map((form, i) => (
              <React.Fragment key={i}>
                <Form.Label className="d-flex my-auto w-25">
                  {form.label}
                </Form.Label>
                <Form.Select
                  name={form.key}
                  className="my-2 w-75"
                  aria-label="Default select example"
                  size="sm"
                  placeholder={form.label}
                  value={subtitle[form.key]}
                  onChange={(e) => {
                    setsubtitle({
                      ...subtitle,
                      [form.key]: e.target.value,
                    })
                  }}
                >
                  {options[form.key].map((option) => (
                    <option value={option}>
                      {colorName[option] || weightName[option] || option}
                    </option>
                  ))}
                </Form.Select>
              </React.Fragment>
            ))}
          </Col>
          <Col xs={8} className="px-3">
            <Form.Label className="d-flex my-auto mt-auto w-100 mb-3">
              字幕位置
            </Form.Label>
            <div className="w-100">
              <Form.Range
                value={subtitle.y_position * 100}
                onChange={(e) =>
                  setsubtitle({
                    ...subtitle,
                    y_position: e.target.value / 100,
                  })
                }
              />
            </div>
            {/* <Form.Label className="d-flex my-auto mt-auto w-100 mb-3">
              背景透明度
            </Form.Label>
            <div className="w-100">
              <Form.Range
                value={100 - subtitle.background_opacity * 100}
                onChange={(e) =>
                  setsubtitle({
                    ...subtitle,
                    background_opacity: (100 - e.target.value) / 100,
                  })
                }
              />
            </div> */}
            <div
              className="border position-relative m-auto mt-3"
              style={{
                width: `${dimension.width * scale}px`,
                height: `${dimension.height * scale}px`,
              }}
            >
              <ReactCrop disabled crop={crop} aspect={rate} onChange={() => {}}>
                <div
                  className="d-flex position-relative"
                  style={{
                    width: `${dimension.width * scale}px`,
                    height: `${dimension.height * scale}px`,
                  }}
                >
                  <div
                    className="position-absolute w-100"
                    style={{
                      top: `${subtitle.y_position * 100}%`,
                      left: 0,
                      height: `${
                        parseInt(subtitle.font_size.replace('px', ''), 10) * 1.5
                      }px`,
                      backgroundColor: subtitle.background_color,
                      color: subtitle.font_color,
                      opacity:
                        1 - subtitle.background_opacity.replace('%', '') * 0.01,
                    }}
                  />
                  <div
                    className="position-absolute w-100 text-center"
                    style={{
                      fontFamily: subtitle.font_family,
                      fontSize: subtitle.font_size,
                      fontWeight: weightName[subtitle.font_weight],
                      top: `${subtitle.y_position * 100}%`,
                      left: 0,
                      background: 'transparent',
                      color: subtitle.font_color,
                      backgroundOpacity:
                        1 - subtitle.background_opacity.replace('%', '') * 0.01,
                    }}
                  >
                    qwertyuiopasdfghjklzxcvbnm
                  </div>
                  {thumbnail ? (
                    <video
                      className="m-auto h-100 w-100"
                      src={thumbnail}
                      crossOrigin="anonymous"
                    >
                      <track kind="captions" />
                    </video>
                  ) : (
                    <FormLabel
                      className="d-flex h-100 w-100"
                      style={{ cursor: 'pointer' }}
                      title=""
                    >
                      <FontAwesomeIcon
                        icon={faFilm}
                        className="text-grey h-20 m-auto"
                      />
                    </FormLabel>
                  )}
                </div>
              </ReactCrop>
            </div>
            {/* <div
              className="px-4 m-auto position-relative"
              style={{
                width: '100%',
                height: '27.5rem',
                border: '1px solid #ddd',
                borderRadius: '8px',
                backgroundColor: '#eee',
              }}
            >
              <div
                className="position-absolute w-100 text-center"
                style={{
                  top: `${subtitle.y_position * 100}%`,
                  left: 0,
                }}
              >
                qwertyuiopasdfghjklzxcvbnm
              </div>
            </div> */}
          </Col>
        </Row>
        <div className="pt-3 ps-3">
          <Badge
            bg="white"
            pill
            className="mx-1"
            style={{ border: '.5px solid #000', color: '#000' }}
          >
            白色
          </Badge>
          <Badge bg="black" pill>
            黑色
          </Badge>
          <Badge bg="darkblue" pill className="mx-1">
            深藍
          </Badge>
          <Badge bg="lightblue" pill>
            淺藍
          </Badge>
          <Badge bg="yellow" pill className="mx-1">
            黃色
          </Badge>
          <Badge bg="red" pill>
            紅色
          </Badge>
        </div>
      </Modal.Body>
      <Modal.Footer className="sendForm justify-content-center py-3">
        <Button variant="secondary" onClick={() => handleClose(false)}>
          取消
        </Button>
        <Button variant="chelonia-dark" onClick={() => handleClose(subtitle)}>
          確定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function SettingModal({ setting }) {
  const { show, handleClose } = setting
  const [hashtagOptions, sethashtagOptions] = useState([
    { label: 'tag1', value: 'tag1' },
    { label: 'tag2', value: 'tag2' },
    { label: 'tag3', value: 'tag3' },
  ])
  const handleSelect = (e) => {
    sethashtagOptions(
      hashtagOptions.map(({ label, value, checked }) =>
        value === e
          ? { label, value, checked: !checked }
          : { label, value, checked }
      )
    )
  }

  const [focus, setfocus] = useState(false)
  const { auth } = useContext(AuthContext)

  const handleYoutubeAuth = async () => {
    console.log(auth)
    const res = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/export-target/youtube/workflow/auth/`,
      method: 'POST',
      data: {
        target_name: '',
      },
    })
    console.log(res)
  }

  return (
    <Modal
      style={{ zIndex: '1501' }}
      show={show}
      onHide={() => handleClose()}
      className="py-2 px-4"
      onClick={() => setfocus(false)}
    >
      <Modal.Header closeButton className="h5">
        Youtube 設定
      </Modal.Header>
      <Modal.Body className="px-4 py-0">
        <React.Fragment key="">
          <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
            帳號設定
          </Form.Label>
          <InputGroup className="d-flex">
            <Button
              variant="outline-chelonia"
              className="w-100 text-center"
              onClick={handleYoutubeAuth}
            >
              Sign in with
              <FontAwesomeIcon
                className="text-danger fs-5 px-2"
                icon={faYoutube}
              />
              Youtube
            </Button>
          </InputGroup>
        </React.Fragment>
        <React.Fragment key="">
          <Form.Label className="mb-1 mt-3 fw-bold text-chelonia">
            個人設定
          </Form.Label>
          <Card className="bg-light border p-3 fs-7">
            <Row className="justifu-content-around fs-6 fw-bold text-chelonia-light">
              <Col xs={6}>預設頻道</Col>
              <Col xs={6} className="text-end">
                <FontAwesomeIcon icon={faCircleUser} />
                &ensp; USER_NAME
              </Col>
            </Row>
            <Row className="px-4 py-2">
              這頻道是 CHANNEL_NAME 預設的 YouTube 頻道。所有自動上傳的
              highlight 會上傳至此頻道。
            </Row>
            <hr className="my-2 " />
            <Row className="justifu-content-around fs-6 fw-bold text-chelonia-light">
              <Col xs={6}>自動上傳所有 Highlight</Col>
              <Col xs={6} className="text-end">
                <Form.Check type="switch" id="custom-switch" />
              </Col>
            </Row>
            <Row className="px-4 py-2">
              如果選擇，所有輸出的 highlight 將會自動上傳為私人影片。
            </Row>
          </Card>
        </React.Fragment>
        <React.Fragment key="">
          <Form.Label className="mt-3 mb-2 fw-bold text-chelonia">
            上傳預設值
          </Form.Label>
          <Card className="bg-light border px-2 py-3 fs-7">
            <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">標題</Form.Label>
              <Form.Control readOnly size="sm" type="text" />
            </InputGroup>
            <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">說明</Form.Label>
              <Form.Control readOnly size="sm" type="text" />
            </InputGroup>
            <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">類別</Form.Label>
              <Form.Select size="sm" aria-label="請選擇類別">
                <option>請選擇類別...</option>
                <option value="1">電影與動畫</option>
                <option value="2">汽車與車輛</option>
                <option value="3">音樂</option>
                <option value="4">寵物與動物</option>
                <option value="5">運動</option>
                <option value="6">短片</option>
                <option value="7">旅行與生活</option>
                <option value="8">遊戲</option>
                <option value="9">Video Bloggin</option>
                <option value="10">人物與網誌</option>
                <option value="11">喜劇</option>
                <option value="12">娛樂</option>
                <option value="13">新聞與政治</option>
                <option value="14">DIY 教學與生活風格</option>
                <option value="15">教育</option>
                <option value="16">科學與技術</option>
                <option value="17">電影</option>
                <option value="18">動畫/動漫</option>
              </Form.Select>
            </InputGroup>
            {/* <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">標記</Form.Label>
              <Form.Control
                readOnly
                size="sm"
                type="text"
                placeholder="hashtag 多選輸入框..."
              />
            </InputGroup> */}
            <AutoComplete
              setting={{
                text: '標記',
                placeholder: 'hashtag 多選輸入框...',
                options: hashtagOptions,
                handleSelect: (e) => handleSelect(e),
                show: focus,
                onFocus: () => setfocus(true),
              }}
            />
            <InputGroup className="mb-2">
              <Form.Label className="px-2 my-auto">權限</Form.Label>
              <Form.Select size="sm" aria-label="請選擇類別">
                <option>請選擇權限...</option>
                <option value="1">公開</option>
                <option value="2">私人</option>
                <option value="3">Unlisted</option>
              </Form.Select>
            </InputGroup>
          </Card>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          className="ms-auto"
          style={{ boxShadow: 'none' }}
          variant="secondary"
          onClick={() => handleClose()}
        >
          取 消
        </Button>
        <Button
          className="me-auto"
          style={{ boxShadow: 'none' }}
          variant="chelonia-dark"
          onClick={() => handleClose()}
        >
          儲 存
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function AutoComplete({ setting }) {
  const { text, options, placeholder, show, onFocus, handleSelect } = setting
  const [search, setsearch] = useState('')
  const selected = useMemo(
    () => options.filter(({ checked }) => checked),
    [options]
  )

  return (
    <div
      className="w-100 position-relative mb-2"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      aria-hidden
    >
      <div className="d-flex flex-nowrap w-100">
        <div className="d-flex px-2">
          <Form.Label className="my-auto text-nowrap">{text}</Form.Label>
        </div>
        <div className="flex-fill">
          <div className="input-container">
            {selected.map((s) => (
              <div className="input-tag d-flex">
                <span>{s.label}</span>
                <FontAwesomeIcon
                  className="ms-2 fs-7 my-auto"
                  style={{
                    cursor: 'pointer',
                  }}
                  icon={faTimes}
                  onClick={() => handleSelect(s.value)}
                />
              </div>
            ))}
            <Form.Control
              className="p-0"
              onFocus={onFocus}
              placeholder={show || selected.length ? '' : placeholder}
            />
          </div>
        </div>
      </div>
      <div
        className="position-absolute w-100 border"
        style={{
          top: '100%',
          height: '500px',
          display: show ? 'block' : 'none',
          zIndex: '999999',
          backgroundColor: 'white',
        }}
      >
        <div className="mb-2 p-2 w-100 d-flex flex-column">
          <InputGroup
            style={{ borderRadius: '.25rem' }}
            className="border rounded-lg"
            size="sm"
          >
            <Form.Control
              type="text"
              placeholder="Search..."
              aria-label="Search..."
              aria-describedby="btnGroupAddon"
              title="輸 入 關 鍵 字 搜 尋"
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </InputGroup>
          <hr />
          <div
            className="scrollbarShow"
            style={{ maxHeight: '25rem', overflowY: 'auto' }}
          >
            {options
              .filter(({ label }) => !search || label.includes(search))
              .map(({ label, value, checked }) => (
                <>
                  <ListGroupItem
                    title={label}
                    className="px-3 py-0 d-flex"
                    key={value}
                    href=""
                    onClick={() => handleSelect(value)}
                  >
                    <span>{label}</span>
                    {checked && (
                      <FontAwesomeIcon
                        className="ms-auto my-auto"
                        icon={faCheckCircle}
                      />
                    )}
                  </ListGroupItem>
                  <hr />
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function SelectModal({ setting }) {
  const { show, handleClose } = setting
  const [selected, setSelected] = useState({})

  return (
    <Modal
      style={{ zIndex: '1501' }}
      size="xl"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header
        className="AccFormModal justify-content-center text-center pt-4"
        closeButton
      >
        <Modal.Title>
          <Row classNamew-100>
            <h5>選擇匯入素材</h5>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="d-flex flex-column pt-0"
        style={{ height: '70vh' }}
      >
        <MaterialList
          setting={{
            hasCheckBox: true,
            selectedMaterial: selected,
            allowTypes: ['ta', 'video'],
            hasTools: false,
            handleSelect: (object_id) =>
              setSelected({
                ...selected,
                [object_id]: !selected[object_id],
              }),
          }}
        />
      </Modal.Body>
      <Modal.Footer className="sendForm justify-content-center py-3">
        <Button variant="secondary" onClick={() => handleClose(false)}>
          取消
        </Button>
        <Button
          variant="chelonia-dark"
          onClick={() =>
            handleClose(Object.keys(selected).filter((key) => selected[key]))
          }
        >
          確定
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

function FilmEditor() {
  //   const navigate = useNavigate()
  const { auth } = useContext(AuthContext)
  const {
    materials = [],
    projects = [],
    videos = [],
    // shadows,
    // setVideos,
    getVideos = () => [],
    handleSilentUpload = () => {},
    handleModify = () => {},
  } = useContext(UploaderContext)

  const [warn, setWarn] = useState({
    show: false,
    text: '',
    handleClose: () => {},
  })
  const [material, setMaterial] = useState({
    show: false,
    type: '',
    handleClose: () => {},
  })
  const [watermark, setwatermark] = useState({
    show: false,
    type: '',
    handleClose: () => {},
  })
  const [subtitles, setsubtitles] = useState({
    show: false,
    type: '',
    handleClose: () => {},
  })

  const { project_id } = useParams()
  const products = useMemo(
    () =>
      videos.filter(({ description }) => {
        const attr = parseJSON(description, {
          size: '',
          created_on: '',
          created_by: '',
        })
        return (
          attr.type &&
          attr.type === 'product' &&
          attr.project_id &&
          attr.project_id === project_id
        )
      }),
    [project_id, videos]
  )

  const [generating, setgenerating] = useState({
    subline: false,
    clip: false,
    video: false,
  })
  const [exporting, setexporting] = useState(false)

  const formatTime = (second) => {
    if (!second) return '00:00:00'
    return new Date(parseFloat(second) * 1000).toISOString().substr(11, 8)
  }

  const grid = 0

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: grid,
    // width: '100%',
  })

  // const [tempSearch, setTempSearch] = useState('')
  // const [setSearch] = useState('')
  // const [focus, setFocus] = useState(false)

  const handleDownload = async (view_url, name) => {
    const videoData = await apiServices.extenal({
      url: view_url,
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'video/mp4',
      },
    })
    const blob = new Blob([videoData.data], {
      type: 'video/mp4',
    })
    const url = URL.createObjectURL(blob)
    // const file = new File([blob], video.name)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const [crop, setcrop] = useState({
    show: false,
    top_left_x: 0,
    top_left_y: 0,
    lower_right_x: 0,
    lower_right_y: 0,
  })

  const [map, setMap] = useState([])
  const [WM, setWM] = useState('')
  const [WMP, setWMP] = useState('{}')

  const handleAddMaterial = (material_ids) => {
    setMap([...map, ...material_ids])
  }
  const handleDeleteMaterial = (material_ids) => {
    console.log(map)
    console.log(material_ids)
    setMap(map.filter((id) => !material_ids.includes(id)))
  }

  const clipDuration = useMemo(
    () =>
      map.reduce((prev, cur) => {
        const target = materials.find(({ object_id }) => object_id === cur)
        if (!target || !target.duration) return prev
        return prev + parseFloat(target.duration)
      }, 0),
    [map]
  )

  const handleResortClips = async (e) => {
    const result = Array.from(map)
    const [removed] = result.splice(e.source.index, 1)
    result.splice(e.destination.index, 0, removed)
    setMap(result)
  }

  const reupload = async (url) => {
    console.log('download to reupload')
    const videoData = await apiServices.extenal({
      url,
      method: 'get',
      responseType: 'arraybuffer',
    })
    const blob = new Blob([videoData.data])
    const file = new File(
      [blob],
      `${
        projects.find(({ object_id }) => object_id === project_id)?.name ||
        '專案'
      }_成品_${'0'.repeat(3 - `${products.length + 1}`.length)}${
        products.length + 1
      }`
    )
    console.log('download end, start to reupload')
    handleSilentUpload('video', file, async (v) => {
      await handleModify('video', v.object_id || '', {
        description: JSON.stringify({
          type: 'product',
          size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
          duration: clipDuration,
          created_on: moment().format('yyyy-MM-DD HH:mm:ss'),
          created_by: auth.username,
          project_id,
        }),
      })
      console.log('modify end')
      await getVideos()
      setexporting(false)
    })
  }

  const [inited, setinited] = useState(false)
  const getVE = async () => {
    const target = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/`,
      method: 'get',
    })
    console.log(target)
    if (target) {
      const WMMap = await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/watermark-map/`,
        method: 'get',
      })
      const TAMap = await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/transition-animation-map/`,
        method: 'get',
      })
      const CLIPMap = await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/video-slice-map/`,
        method: 'get',
      })

      // init subtitle
      const subtitleConfigMap = await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/subtitle-config-map/`,
        method: 'get',
      })
      console.log(subtitleConfigMap.data)
      if (subtitleConfigMap.data[0]) {
        setsubtitles({
          ...subtitles,
          ...{
            ...subtitleConfigMap.data[0],
            font_size: `${subtitleConfigMap.data[0].font_size}px`,
            background_opacity: '50%',
            background_color:
              subtitleConfigMap.data[0].background_color === '無'
                ? '#000000'
                : subtitleConfigMap.data[0].background_color,
          },
        })
      }

      // init crop
      const cropConfigMap = await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/crop-config-map/`,
        method: 'get',
      })
      console.log(cropConfigMap.data)
      if (cropConfigMap.data[0]) {
        setcrop({
          ...crop,
          ...cropConfigMap.data[0],
        })
      }

      const resorted = WMMap.data
        .concat(TAMap.data)
        .concat(CLIPMap.data)
        .sort((a, b) => b.order - a.order)
        .map(
          ({
            source_clip,
            source_video,
            source_watermark,
            source_transition_animation,
            x_position,
            y_position,
          }) => {
            if (source_watermark) {
              console.log('watermark')
              setWM(source_watermark)
              setWMP(
                JSON.stringify({
                  x_position: parseInt(x_position, 10),
                  y_position: parseInt(y_position, 10),
                })
              )
              return ''
            }
            return (
              source_clip ||
              source_video ||
              source_watermark ||
              source_transition_animation
            )
          }
        )
        .filter((id) => id)
      console.log(resorted)
      console.log(materials)
      setMap(resorted)
    }
    setinited(true)
  }
  useEffect(() => {
    console.log('---initing VE---')
    console.log(project_id)
    console.log(materials)
    if (!inited && materials.length && project_id) getVE()
  }, [project_id, materials, inited])

  const audioElmRef = useRef(null)

  const [playbackRate, setplaybackRate] = useState('')
  useEffect(() => {
    if (audioElmRef.current && playbackRate) {
      audioElmRef.current.playbackRate = playbackRate
    }
  }, [playbackRate])

  const [outputRate, setoutputRate] = useState('')

  const { setToast } = useContext(ToastContext)
  const [exportedVideos, setexportedVideos] = useState([])
  const getExported = async () => {
    const res = await apiServices.data({
      // path: `video/management/${video_id}/subtitle-generation-task/`,
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/exported/project/`,
      method: 'get',
    })
    setexportedVideos(res.data)
    return res.data
  }
  useEffect(() => {
    getExported()
  }, [])

  const [exportedVideo, setexportedVideo] = useState({})
  const handleBindClips = async (refresh = false) => {
    // checking work first
    const checkingtask = await apiServices.data({
      // path: `video/management/${video_id}/subtitle-generation-task/`,
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/exporting-task/project/`,
      method: 'get',
    })
    console.log(`checking tasks`)
    if (checkingtask.data && checkingtask.data.length) {
      await Promise.all(
        checkingtask.data.map(async ({ object_id }) => {
          const deleted = await apiServices.data({
            // path: `video/management/${video_id}/subtitle-generation-task/`,
            path: `org/${
              auth.orgs[auth.selectedOrg || 0].object_id
            }/project/${project_id}/exporting-task/project/${object_id}/`,
            method: 'delete',
          })
          console.log(deleted)
        })
      )
    }

    // console.log(selectedClips)
    setgenerating({
      ...generating,
      video: true,
    })
    console.log(map)

    // delay task after delete
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    await delay(5000)

    if (!map.length) {
      setgenerating({
        ...generating,
        video: false,
      })
      setWarn({
        show: true,
        text: '請選擇一個以上的片段',
        handleClose: () =>
          setWarn({
            ...warn,
            show: false,
          }),
      })
      return
    }
    const WMMap = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/watermark-map/`,
      method: 'get',
    })
    const TAMap = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/transition-animation-map/`,
      method: 'get',
    })
    const CLIPMap = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/video-slice-map/`,
      method: 'get',
    })
    await Promise.all(
      WMMap.data.map(async ({ object_id }) => {
        await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/project/${project_id}/watermark-map/${object_id}/`,
          method: 'delete',
        })
        return null
      })
    )
    await Promise.all(
      TAMap.data.map(async ({ object_id }) => {
        await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/project/${project_id}/transition-animation-map/${object_id}/`,
          method: 'delete',
        })
        return null
      })
    )
    await Promise.all(
      CLIPMap.data.map(async ({ object_id }) => {
        await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/project/${project_id}/video-slice-map/${object_id}/`,
          method: 'delete',
        })
        return null
      })
    )

    if (WM) {
      console.log(WMP)
      const { x_position, y_position } = JSON.parse(WMP)
      await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/watermark-map/`,
        method: 'post',
        data: {
          source_watermark: WM,
          x_position: parseInt(x_position, 10),
          y_position: parseInt(y_position, 10),
        },
      })
    }
    map
      // .filter((m) => m !== first)
      .reduce(async (prev, sc, index) => {
        const target = materials.find(({ object_id }) => object_id === sc)
        const paths = {
          clip: 'clip-map',
          wm: 'watermark-map',
          ta: 'transition-animation-map',
          video: 'video-slice-map',
        }
        const sources = {
          video: 'source_video',
          clip: 'source_clip',
          wm: 'source_watermark',
          ta: 'source_transition_animation',
        }
        if (target.type === 'video') {
          console.log(target)
          const start = 0
          const end = target.duration - 0.1
          await apiServices.data({
            path: `org/${
              auth.orgs[auth.selectedOrg || 0].object_id
            }/project/${project_id}/${paths[target.type || 'clip']}/`,
            method: 'post',
            data: {
              [sources[target.type || 'clip']]: sc,
              order: index + 1,
              start,
              end,
            },
          })
        } else {
          await apiServices.data({
            path: `org/${
              auth.orgs[auth.selectedOrg || 0].object_id
            }/project/${project_id}/${paths[target.type || 'clip']}/`,
            method: 'post',
            data: {
              [sources[target.type || 'clip']]: sc,
              order: index + 1,
            },
          })
        }
        return []
      }, [])

    // subtitle config map
    if (subtitles.background_opacity) {
      console.log('background_opacity is setted')
      console.log(subtitles)
      const subtitleConfigMap = await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/subtitle-config-map/`,
        method: 'get',
      })
      if (subtitleConfigMap.data[0]) {
        await apiServices.data({
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/project/${project_id}/subtitle-config-map/${
            subtitleConfigMap.data[0].object_id
          }/`,
          method: 'delete',
        })
      }
      console.log(subtitles)
      await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/subtitle-config-map/`,
        method: 'post',
        data: {
          y_position: 0.9,
          font_color: '#3B5291',
          font_stroke: '2',
          background_color: '#84DFF6',
          ...subtitles,
          font_size: subtitles.font_size
            ? subtitles.font_size.replace('px', '')
            : 25,
          background_opacity: subtitles.background_opacity
            ? subtitles.background_opacity.replace('%', '') * 0.01
            : '0.8',
        },
      })
    }

    // output map
    const outputConfigMap = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/output-config-map/`,
      method: 'get',
    })
    if (outputConfigMap.data[0]) {
      await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/output-config-map/${
          outputConfigMap.data[0].object_id
        }/`,
        method: 'delete',
      })
    }
    await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/output-config-map/`,
      method: 'post',
      data: {
        play_speed: outputRate || 1,
      },
    })

    // crop config map
    const cropConfigMap = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/crop-config-map/`,
      method: 'get',
    })
    if (cropConfigMap.data[0]) {
      await apiServices.data({
        path: `org/${
          auth.orgs[auth.selectedOrg || 0].object_id
        }/project/${project_id}/crop-config-map/${
          cropConfigMap.data[0].object_id
        }/`,
        method: 'delete',
      })
    }
    await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/crop-config-map/`,
      method: 'post',
      data: {
        top_left_x: crop.top_left_x,
        top_left_y: crop.top_left_y,
        lower_right_x: crop.lower_right_x,
        lower_right_y: crop.lower_right_y,
      },
    })

    // subtitle task
    const subtitleTask = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/exporting-task/subtitle/`,
      method: 'post',
    })
    console.log(subtitleTask)
    const exportTask = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/exporting-task/project/`,
      method: 'post',
    })
    console.log(exportTask)
    const getStatus = async (clearFunc) => {
      try {
        const task = await apiServices.data({
          // path: `video/management/${video_id}/subtitle-generation-task/`,
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/project/${project_id}/exporting-task/project/`,
          method: 'get',
        })
        const taskStatus = task.data.find(
          (t) => t.object_id === exportTask.data.object_id
        ).task_status
        console.log(taskStatus)
        const exported = await apiServices.data({
          // path: `video/management/${video_id}/subtitle-generation-task/`,
          path: `org/${
            auth.orgs[auth.selectedOrg || 0].object_id
          }/project/${project_id}/exported/project/`,
          method: 'get',
        })
        if (taskStatus === 'FINISHED') {
          console.log('export finished')
          console.log(exported)
          // refreshSubtitle()
          if (exported.data && exported.data[0]) {
            setexportedVideo({
              ...exported.data[0],
              loading: true,
            })
          }
          setgenerating({
            ...generating,
            video: false,
          })
          clearFunc()
          const end = await getExported()
          if (refresh) {
            getVE()
            console.log(end)
            reupload(end[0].view_url)
          }
        }
        if (taskStatus === 'FAILED') {
          console.log('export failed')
          setToast({
            show: true,
            text: `failed to export video: ${
              task.data.description || 'please reinvest settings'
            }`,
          })
          // refreshSubtitle()
          setexportedVideo({})
          setgenerating({
            ...generating,
            video: false,
          })
          clearFunc()
        }
      } catch (e) {
        console.log(e)
        clearFunc()
      }
    }

    getStatus()
    const interval = setInterval(() => {
      getStatus(() => clearInterval(interval))
    }, 5000)
  }

  // const [editingClip, seteditingClip] = useState('')
  // const handleEditClip = async (data) => {
  //   await apiServices.data({
  //     path: `clip/management/${editingClip}/`,
  //     method: 'patch',
  //     data,
  //   })
  //   getClips()
  //   seteditingClip('')
  // }

  const [sort, setsort] = useState('ASC')
  const [clippedSort, setclippedSort] = useState('ASC')

  const handleVideoClear = async () => {
    setMap([])
    setexportedVideo({})
  }

  const handleDeleteExported = async (value) => {
    await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/project/${project_id}/exported/project/${value}`,
      method: 'delete',
    })
    // getExported()
    setexportedVideos([])
  }

  const [settingShow, setsettingShow] = useState(false)
  const handleSettingClose = (id) => {
    setsettingShow(false)
    if (id) handleSettingClose(id)
  }

  const sortedMap = map
    .filter((id) => materials.find(({ object_id }) => object_id === id))
    .map((id) => materials.find(({ object_id }) => object_id === id))
    .sort(
      (a, b) =>
        (sort === 'ASC' ? -1 : 1) * (a.created_on - b.created_on > 0 ? 1 : -1)
    )

  const dimension = useMemo(() => {
    if (sortedMap.length) {
      console.log(sortedMap[0].dimension)
      if (!sortedMap[0].dimension) {
        return {
          width: 1280,
          height: 720,
        }
      }
      return sortedMap[0].dimension
    }
    return {
      width: 1280,
      height: 720,
    }
  }, [sortedMap])

  // useEffect(() => {
  //   console.log('this is triggered by needupdate')
  //   if (inited && map.length && needUpdate) handleBindClips(false)
  //   setneedUpdate(false)
  // }, [needUpdate])

  // useEffect(() => {
  //   console.log('this is triggered by map')
  //   if (inited && map.length) handleBindClips(false)
  // }, [map])

  // exported control
  const [exportedclicked, setexportedclicked] = useState(false)
  console.log(products)

  const checkingExported = async (url, clearFunc) => {
    const res = await apiServices.extenal({
      url,
      method: 'get',
    })
    console.log('checking 404')
    console.log(res)
    if (!res.error) {
      clearFunc()
    }
  }
  useEffect(() => {
    if (exportedVideo.loading) {
      const interval = setInterval(() => {
        checkingExported(exportedVideo.view_url, () => {
          setexportedVideo({
            ...exportedVideo,
            loading: false,
          })
          clearInterval(interval)
        })
      }, 5000)
    }
  }, [exportedVideo])

  const [subtitle, setsubtitle] = useState([])
  const getSubtitle = async (offset = 0) => {
    const res = await apiServices.data({
      path: `org/${
        auth.orgs[auth.selectedOrg || 0].object_id
      }/footage/subtitle/management/?limit=100&offset=${offset}`,
      method: 'get',
    })
    if (res.count && res.count >= offset + 100) {
      const res2 = await getSubtitle(offset + 100)
      return res.data.concat(res2.data)
    }
    return res.data
  }
  const refreshSubtitle = async () => {
    const res = await getSubtitle()
    if (res.length) {
      setsubtitle(res)
    }
  }
  useEffect(() => {
    refreshSubtitle()
  }, [])

  return (
    <Container className="d-flex p-3 h-100 w-100">
      <Row className="pb-2 h-100" style={{ width: '100vw' }}>
        <Col xs={7} style={{ height: '77%' }}>
          <Card className="d-flex w-100 h-100">
            <div className="my-auto me-auto py-2 px-2">
              <div className="fw-bold text-start text-chelonia-light my-auto pe-0">
                影片預覽
              </div>
            </div>
            <hr className="my-1 mx-2" />
            {exporting ? (
              <Col className="h-100 w-100 d-flex flex-column">
                <h5 className="text-grey m-auto d-flex justify-content-center">
                  <Spinner size="sm" className="me-2 my-auto" />
                  影片匯出中...
                </h5>
              </Col>
            ) : generating.video ? (
              <Col className="h-100 w-100 d-flex flex-column">
                <h5 className="text-grey m-auto d-flex justify-content-center">
                  <Spinner size="sm" className="me-2 my-auto" />
                  影片生成中...
                </h5>
              </Col>
            ) : exportedVideo.loading ? (
              <Col className="h-100 w-100 d-flex flex-column">
                <h5 className="text-grey m-auto d-flex justify-content-center">
                  <Spinner size="sm" className="me-2 my-auto" />
                  等待影片上傳中...
                </h5>
              </Col>
            ) : exportedVideo.view_url ? (
              <Col className="h-100 w-100 d-flex bg-black flex-column">
                <video
                  className="m-auto h-100 w-100"
                  controls
                  ref={audioElmRef}
                  src={exportedVideo.view_url}
                  crossOrigin="anonymous"
                >
                  <track kind="captions" />
                </video>
              </Col>
            ) : false ? (
              <Preview
                setting={{
                  ...crop,
                  ...watermark,
                  subtitles,
                  WM,
                  setWM,
                  WMP,
                  setWMP,
                  thumbnail: sortedMap[0] ? sortedMap[0].view_url : '',
                }}
              />
            ) : (
              <FormLabel
                // htmlFor="file"
                className="d-flex h-100 w-100"
                style={{ cursor: 'pointer' }}
                title="影 片 上 傳"
              >
                <FontAwesomeIcon
                  icon={faFilm}
                  className="text-grey h-20 m-auto"
                />
              </FormLabel>
            )}
          </Card>
          <Card
            className="w-100 mt-4"
            // style={{
            //   height: '25%',
            // }}
          >
            <div className="my-auto me-auto px-2 py-2">
              <div className="fw-bold text-start text-chelonia-light my-auto pe-0">
                快速選項／匯出設定
              </div>
            </div>
            <hr className="my-1 mx-2" />
            <Row className="my-auto px-2 py-3">
              <Col xs={5} className="d-flex">
                <Button
                  size="sm"
                  className="px-2 my-auto"
                  style={{ boxShadow: 'none' }}
                  onClick={() => {
                    if (audioElmRef.current) {
                      if (audioElmRef.current.currentTime > 5)
                        audioElmRef.current.currentTime -= 5
                      else audioElmRef.current.currentTime = 0
                    }
                  }}
                  variant="light"
                  title="-5s"
                >
                  -5s
                </Button>
                <Button
                  size="sm"
                  className="px-2 ms-1 me-3 my-auto"
                  style={{ boxShadow: 'none' }}
                  variant="light"
                  onClick={() => {
                    if (audioElmRef.current) {
                      if (audioElmRef.current.currentTime > 1)
                        audioElmRef.current.currentTime -= 1
                      else audioElmRef.current.currentTime = 0
                    }
                  }}
                  title="-1s"
                >
                  -1s
                </Button>

                <Button
                  size="sm"
                  className="px-2 ms-auto my-auto"
                  style={{ boxShadow: 'none' }}
                  variant="light"
                  title="+1s"
                  onClick={() => {
                    if (audioElmRef.current) {
                      if (
                        audioElmRef.current.currentTime <
                        audioElmRef.current.duration - 1
                      )
                        audioElmRef.current.currentTime += 1
                      else
                        audioElmRef.current.currentTime =
                          audioElmRef.current.duration
                    }
                  }}
                >
                  +1s
                </Button>
                <Button
                  size="sm"
                  className="px-2 ms-1 my-auto"
                  style={{ boxShadow: 'none' }}
                  variant="light"
                  title="+5s"
                  onClick={() => {
                    if (audioElmRef.current) {
                      if (
                        audioElmRef.current.currentTime <
                        audioElmRef.current.duration - 5
                      )
                        audioElmRef.current.currentTime += 5
                      else
                        audioElmRef.current.currentTime =
                          audioElmRef.current.duration
                    }
                  }}
                >
                  +5s
                </Button>
                <Form.Select
                  name="pic"
                  className="my-auto ms-3"
                  aria-label="Default select example2"
                  onChange={(e) => setplaybackRate(e.target.value)}
                  value={playbackRate}
                  size="sm"
                >
                  <option value="" className="d-none">
                    播放速度
                  </option>
                  {[
                    { label: 'x0.25', value: 0.25 },
                    { label: 'x0.5', value: 0.5 },
                    { label: 'x0.75', value: 0.75 },
                    { label: '正常速度', value: 1 },
                    { label: 'x1.25', value: 1.25 },
                    { label: 'x1.5', value: 1.5 },
                    { label: 'x1.75', value: 1.75 },
                    { label: 'x2.0', value: 2 },
                  ].map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col
                xs={7}
                className="d-flex pe-0"
                style={{ borderLeft: '1.5px solid rgb(171 171 171)' }}
              >
                {/* <Form.Select
                  name="pic"
                  className="my-auto ms-auto"
                  aria-label="Default select example2"
                  // onChange={(e) => setplaybackRate(e.target.value)}
                  // value={playbackRate}
                  size="sm"
                >
                  <option value="" className="d-none">
                    畫面比例
                  </option>
                  {[
                    { label: '橫式16:9', value: 0.25 },
                    { label: '直式9:16', value: 0.5 },
                    { label: '方型1:1', value: 0.75 },
                  ].map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select> */}
                <Button
                  size="sm"
                  className="mx-2"
                  variant="outline-secondary"
                  style={{ right: '0%', top: '0%' }}
                  onClick={() => {
                    setcrop({
                      ...crop,
                      show: true,
                    })
                  }}
                >
                  畫面比例
                </Button>
                <Button
                  size="sm"
                  className="mx-2"
                  variant="outline-secondary"
                  style={{ right: '0%', top: '0%' }}
                  onClick={() =>
                    setsubtitles({
                      ...subtitles,
                      show: true,
                      handleClose: (value) => {
                        // if (value) setneedUpdate(true)
                        setsubtitles({
                          ...subtitles,
                          ...value,
                          show: false,
                        })
                      },
                    })
                  }
                >
                  字幕設定
                </Button>
                <Button
                  size="sm"
                  className="mx-2"
                  variant="outline-secondary"
                  style={{ right: '0%', top: '0%' }}
                  onClick={() =>
                    setwatermark({
                      show: true,
                      handleClose: (value) => {
                        console.log(value)
                        // if (value) setneedUpdate(true)
                        setwatermark({
                          ...watermark,
                          show: false,
                        })
                      },
                    })
                  }
                >
                  浮水印／影片邊框
                </Button>
                <Form.Select
                  name="pic"
                  className="my-auto w-22 mx-3"
                  aria-label="Default select example2"
                  onChange={(e) => setoutputRate(e.target.value)}
                  value={outputRate}
                  size="sm"
                >
                  <option value="" className="d-none">
                    匯出速度
                  </option>
                  {[
                    { label: 'x0.25', value: 0.25 },
                    { label: 'x0.5', value: 0.5 },
                    { label: 'x0.75', value: 0.75 },
                    { label: '正常速度', value: 1 },
                    { label: 'x1.25', value: 1.25 },
                    { label: 'x1.5', value: 1.5 },
                    { label: 'x1.75', value: 1.75 },
                    { label: 'x2.0', value: 2 },
                  ].map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col className="h-100 pe-4" xs={5}>
          <Card className="w-100" style={{ height: '57%' }}>
            <Row className="d-flex p-3 pt-2 pb-0">
              <Col
                xs={6}
                className="d-flex fw-bold text-start text-chelonia-light my-auto pe-0"
              >
                <div className="my-auto">素材剪輯區</div>
                <Button
                  size="sm"
                  className="w-40 me-auto ms-3"
                  variant="outline-secondary"
                  style={{ right: '0%', top: '0%' }}
                  onClick={() =>
                    setMaterial({
                      show: true,
                      type: '轉場動畫',
                      handleClose: (value) => {
                        if (value) handleAddMaterial(value)
                        setMaterial({
                          ...material,
                          show: false,
                        })
                      },
                    })
                  }
                >
                  匯入素材&ensp;
                  <FontAwesomeIcon icon={faCirclePlus} />
                </Button>
              </Col>
              <Col xs={6} className="d-flex px-0">
                <div className="d-flex w-100">
                  {/* <Form.Select
                    name="pic"
                    className="w-32 my-auto"
                    aria-label="Default select example"
                    onChange={(e) => setWM(e.target.value)}
                    value={WM}
                    size="sm"
                  >
                    <option value="" className="d-none">
                      浮水印
                    </option>
                    {materials
                      .filter(({ type }) => type === 'wm')
                      .map(({ name, object_id }) => (
                        <option key={object_id} value={object_id}>
                          {name}
                        </option>
                      ))}
                  </Form.Select> */}
                  <Form.Select
                    name="pic"
                    className="my-auto ms-auto"
                    style={{ width: '32%' }}
                    aria-label="Default select example2"
                    onChange={(e) => setsort(e.target.value)}
                    value={sort}
                    size="sm"
                  >
                    <option value="" className="d-none2">
                      排列
                    </option>
                    {[
                      { label: '新至舊', value: 'ASC' },
                      { label: '舊至新', value: 'DESC' },
                    ].map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                  <div className="w-25 my-auto">
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      className="my-auto"
                      style={{ cursor: 'pointer' }}
                      onClick={handleVideoClear}
                    >
                      清除
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="mx-2 mt-2 mb-1" />
            <Card
              className="d-flex flex-fill my-auto m-2 text-secondary"
              style={{ overflowY: 'auto' }}
            >
              {!inited ? (
                <Col className="h-100 w-100 d-flex flex-column">
                  <h5 className="text-grey m-auto d-flex justify-content-center">
                    <Spinner size="sm" className="me-2 my-auto" />
                    資料載入中...
                  </h5>
                </Col>
              ) : map.length && materials.length ? (
                <DragDropContext onDragEnd={handleResortClips}>
                  <Droppable droppableId="droppable" direction="vertical">
                    {(dropProvided, dropSnapshot) => (
                      <Row
                        className="ps-3"
                        {...dropProvided.droppableProps}
                        ref={dropProvided.innerRef}
                        style={getListStyle(dropSnapshot.isDraggingOver)}
                      >
                        {sortedMap.map(({ object_id, duration, name }, i) => {
                          const subs = subtitle.filter(
                            (s) => s && s.source_video === object_id
                          )
                          return (
                            <Draggable
                              key={object_id}
                              draggableId={object_id}
                              index={i}
                            >
                              {(dragProvided) => (
                                <Row
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.draggableProps}
                                  className="text-start text-nowrap"
                                >
                                  <Col className="d-flex ps-0" xs={1}>
                                    <Button
                                      {...dragProvided.dragHandleProps}
                                      style={{ boxShadow: 'none' }}
                                      variant="edit"
                                      // onClick={() => handleDeleteClip(id)}
                                      title="調 整 順 序"
                                    >
                                      <FontAwesomeIcon icon={faBars} />
                                    </Button>
                                  </Col>
                                  {duration ? (
                                    <Col
                                      className="my-auto ps-1 pe-2 fw-bold text-chelonia-light"
                                      xs={2}
                                    >
                                      <p className="my-auto">
                                        {formatTime(duration)}
                                      </p>
                                    </Col>
                                  ) : (
                                    <Col
                                      xs={2}
                                      className="my-auto ps-1 pe-2 fw-bold text-chelonia-light"
                                    >
                                      00:00:00
                                    </Col>
                                  )}
                                  <Col className="d-flex px-1" xs={6}>
                                    <p
                                      className="my-auto oneLineEllipsis"
                                      title={`${name}`}
                                    >
                                      {name}
                                    </p>
                                  </Col>
                                  <Col className="d-flex px-1" xs={2}>
                                    <p
                                      className="my-auto oneLineEllipsis"
                                      style={{
                                        color: subs.length ? '#2e8ada' : '#666',
                                      }}
                                    >
                                      {subs.length ? '有字幕' : '無字幕'}
                                    </p>
                                  </Col>
                                  <Col className="d-flex p-0" xs={1}>
                                    <Button
                                      className="p-2"
                                      style={{ boxShadow: 'none' }}
                                      variant="lightgrey"
                                      onClick={() =>
                                        handleDeleteMaterial([object_id])
                                      }
                                      title="移 除"
                                    >
                                      <FontAwesomeIcon
                                        className="fs-6"
                                        icon={faCircleMinus}
                                      />
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                            </Draggable>
                          )
                        })}
                      </Row>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className="m-auto">請選擇/加入下方擷取片段以合成</div>
              )}
            </Card>

            <Row className="d-flex px-3 py-1 text-secondary">
              <Col xs={12} className="text-center small">
                影片總長｜ {formatTime(clipDuration)}
              </Col>
            </Row>
            <Row className="d-flex px-3 py-2">
              <Button
                size="sm"
                className="mt-0 mx-auto w-48"
                variant="chelonia-dark"
                style={{ right: '0%', top: '0%' }}
                onClick={() => handleBindClips(false)}
              >
                <FontAwesomeIcon icon={faEye} />
                &ensp; 套用並預覽
              </Button>
              <Button
                size="sm"
                className="mt-0 mx-auto w-48"
                variant="danger"
                style={{ right: '0%', top: '0%' }}
                onClick={async () => {
                  setexporting(true)
                  setexportedclicked(true)
                  handleBindClips(true)
                  // await getVE()
                  // setexporting(false)
                }}
              >
                <FontAwesomeIcon icon={faScissors} />
                &ensp; 匯出影片成品
              </Button>
            </Row>
          </Card>
          <Card className="w-100 mt-3 ps-2" style={{ height: '42%' }}>
            <Row className="d-flex px-1 py-2 pb-0">
              <Col
                xs={4}
                className="fw-bold text-start text-chelonia-light my-auto pe-0"
              >
                影片成品管理
              </Col>
              <Col xs={4} className="d-flex pe-0">
                <Button
                  size="sm"
                  className="d-flex ms-auto"
                  variant="outline-danger"
                  onClick={() => {
                    setsettingShow(true)
                  }}
                >
                  Youtube 設定&ensp;
                  <FontAwesomeIcon icon={faYoutube} className="my-auto" />
                </Button>
              </Col>
              <Col xs={4} className="d-flex">
                <Form.Select
                  name="pic"
                  className="my-auto ms-auto"
                  style={{ width: '56%' }}
                  aria-label="Default select example2"
                  onChange={(e) => setclippedSort(e.target.value)}
                  value={clippedSort}
                  size="sm"
                >
                  <option value="" className="d-none2">
                    排列
                  </option>
                  {[
                    { label: '新至舊', value: 'ASC' },
                    { label: '舊至新', value: 'DESC' },
                  ].map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Form.Select>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  className="my-auto mx-2"
                  style={{ cursor: 'pointer' }}
                  onClick={handleVideoClear}
                >
                  清除
                </Button>
              </Col>
            </Row>
            <hr className="ms-0 me-2 mt-2 mb-1" />
            <Card
              className="d-flex flex-fill my-auto m-2 text-secondary ms-0 me-2 mb-2"
              style={{ overflowY: 'auto' }}
            >
              {(exportedclicked ? products || exportedVideos : []).map(
                ({ view_url, object_id }, i) => (
                  <Row
                    className="text-start text-nowrap px-3 py-1"
                    style={{
                      cursor: 'pointer',
                    }}
                    key={i}
                  >
                    <Col className="d-flex px-1" xs={6}>
                      匯出{i + 1}
                    </Col>
                    <Col className="d-flex p-0 ms-auto" xs={1}>
                      <Button
                        className="ms-auto"
                        style={{ boxShadow: 'none' }}
                        variant="edit"
                        onClick={() => {
                          setexportedVideo({
                            view_url,
                          })
                        }}
                        title="播放／預覽"
                      >
                        <FontAwesomeIcon icon={faCirclePlay} />
                      </Button>
                    </Col>
                    <Col className="d-flex p-0 ms-auto" xs={1}>
                      <Button
                        style={{
                          boxShadow: 'none',
                        }}
                        variant="edit"
                        // onClick={() => {
                        //   handleDownload(view_url, name)
                        // }}
                        title="上傳至youtube"
                      >
                        <FontAwesomeIcon
                          className="fs-6"
                          icon={faSquareYoutube}
                        />
                      </Button>
                    </Col>
                    <Col className="d-flex p-0 ms-auto" xs={1}>
                      <Button
                        style={{
                          boxShadow: 'none',
                        }}
                        variant="edit"
                        onClick={() => {
                          handleDownload(view_url, '匯出.mp4')
                        }}
                        title="下 載"
                      >
                        <FontAwesomeIcon className="fs-6" icon={faDownload} />
                      </Button>
                    </Col>
                    <Col className="d-flex p-0" xs={1}>
                      <Button
                        style={{ boxShadow: 'none' }}
                        variant="red"
                        onClick={() => {
                          setWarn({
                            show: true,
                            text: `確定要刪除匯出影片嗎？`,
                            handleClose: (value) => {
                              if (value) handleDeleteExported(object_id)
                              setWarn({
                                ...warn,
                                show: false,
                              })
                            },
                          })
                        }}
                        title="刪 除"
                      >
                        <FontAwesomeIcon className="fs-6" icon={faTrashCan} />
                      </Button>
                    </Col>
                  </Row>
                )
              )}
            </Card>
          </Card>
        </Col>
      </Row>
      <SettingModal
        setting={{
          show: settingShow,
          name: '',
          handleClose: handleSettingClose,
        }}
      />
      {warn.show && <Warn setting={warn} />}
      {material.show && <SelectModal setting={material} />}
      {watermark.show && (
        <WatermarkModal
          setting={{
            ...crop,
            ...watermark,
            dimension,
            WM,
            setWM,
            WMP,
            setWMP,
            thumbnail: sortedMap[0] ? sortedMap[0].view_url : '',
          }}
        />
      )}
      {crop.show && (
        <CropModal
          setting={{
            ...crop,
            setcrop,
            dimension,
            // src: exportedVideo?.view_url,
            src: '',
            thumbnail: sortedMap[0] ? sortedMap[0].view_url : '',
            handleClose: (value, s) => {
              if (value) {
                console.log(value)
                // const { x, y, height, width } = value
                const x = value.x / s
                const y = value.y / s
                const height = value.height / s
                const width = value.width / s
                // const y = dimension.height * (value.y / 100)
                // const height = dimension.height * (value.height / 100)
                // const width = dimension.width * (value.width / 100)
                const top_left_x = Math.max(
                  parseInt(x, 10) % 2 ? parseInt(x, 10) + 1 : parseInt(x, 10),
                  0
                )
                const top_left_y =
                  parseInt(y, 10) % 2 ? parseInt(y, 10) + 1 : parseInt(y, 10)
                const lower_right_x =
                  parseInt(x + width, 10) % 2
                    ? parseInt(x + width, 10) + 1
                    : parseInt(x + width, 10)
                const lower_right_y =
                  parseInt(y + height, 10) % 2
                    ? parseInt(y + height, 10) + 1
                    : parseInt(y + height, 10)
                setcrop({
                  top_left_x,
                  top_left_y,
                  lower_right_x,
                  lower_right_y,
                  show: false,
                })
                // setneedUpdate(true)
              } else {
                setcrop({
                  ...crop,
                  show: false,
                })
              }
            },
          }}
        />
      )}
      {subtitles.show && (
        <SubtitlesModal
          setting={{
            ...crop,
            ...subtitles,
            dimension,
            subtitles,
            thumbnail: sortedMap[0] ? sortedMap[0].view_url : '',
          }}
        />
      )}
    </Container>
  )
}

SettingModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SelectModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

WatermarkModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

CropModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

SubtitlesModal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

export default FilmEditor
